import tagService from '@/services/tagService';

const state = {
  tags: [],
  globalTags: [],
  projectMap: {}
};

const getters = {
  tags: (st) => st.tags,
  globalTags: (st) => st.globalTags,
  projectMap: (st) => st.projectMap,
  activeTags: (st) => st.tags.filter((tag) => !tag.disabled)
};

const mutations = {
  setProjectMap(st, payload) {
    st.projectMap = payload;
  },
  setGlobalTags(st, payload) {
    st.globalTags = payload;
  },
  setTags(st, payload) {
    st.tags = payload;
  },
  addTag(st, payload) {
    st.tags.push(payload);
  },
  removeTag(st, payload) {
    st.tags = st.tags.filter((tag) => tag.id !== payload[0].id);
  },
  updateTag(st, payload) {
    st.tags = st.tags.map((tag) => (tag.id === payload.id
      ? Object.assign(tag, payload) : tag));
  }
};

const actions = {
  async updateTag({ commit }, properties) {
    if (properties.id) {
      return commit('updateTag', await tagService.updateTag(properties));
    }
    return commit('addTag', await tagService.updateTag(properties));
  },
  async removeTag({ commit }, tagId) {
    commit('removeTag', await tagService.removeTag(tagId));
  },
  async getGlobalTags({ commit }) {
    commit('setIsLoading', { tags: true });
    await commit('setGlobalTags', await tagService.getGlobalTags());
    commit('setIsLoading', { tags: false });
  },
  async getTags({ commit }, includeCount = false) {
    commit('setIsLoading', { tags: true });
    if (includeCount) {
      await commit('setProjectMap', await tagService.getProjectTagCount());
    }
    await commit('setTags', await tagService.getCompanyTags());
    commit('setIsLoading', { tags: false });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
