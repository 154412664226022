import Parse from '@kickbox/common-util/src/parse';
import { replaceVariablesToText } from '@kickbox/common-util';
import store from '@/store';

export default {
  clearTranslation(key, language = null) {
    return Parse.Cloud.run('clearTranslation', { key, language });
  },
  replaceCompanyNameVariable(text) {
    return replaceVariablesToText({ companyName: store.getters.company.name }, text);
  },
  getAvailableTranslation(parseObject, companyLanguages) {
    const companyLanguage = store.getters.company.language;
    return companyLanguages.reduce((acc, val) => {
      if (parseObject.get(val.key)) {
        acc[val.key] = this.replaceCompanyNameVariable(parseObject.get(val.key));
      } else if (parseObject.get(companyLanguage)) {
        acc[val.key] = this.replaceCompanyNameVariable(parseObject.get(companyLanguage));
      } else {
        acc[val.key] = this.replaceCompanyNameVariable(parseObject.get('EN'));
      }
      return acc;
    }, { key: parseObject.get('key') });
  }
};
