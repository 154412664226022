<template>
  <v-dialog
    v-model="value"
    max-width="450"
    persistent
    :overlay-opacity="0.9"
  >
    <v-card class="pa-8 d-flex justify-center">
      <migration
        :email="email"
        @click="logout"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { Migration } from '@kickbox/common-components';
import analyticsService from '@/services/analyticsService';
import userService from '@/services/userService';

export default {
  name: 'MigrationDialog',
  components: {
    Migration
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      email: ''
    };
  },
  created() {
    this.email = userService.getCurrentUserEmail();
    analyticsService.trackCustomEvent('migration-popup-loaded');
  },
  methods: {
    logout() {
      userService.logout();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
