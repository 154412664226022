import Parse from '@kickbox/common-util/src/parse';
import { query } from '@kickbox/common-util/src/util/parse';
import { CompanyService } from '@kickbox/common-admin';
import { ADMIN_ROLES } from '@kickbox/common-util/constants/admin-roles';
import store from '@/store';
import userService from '@/services/userService';

export default {
  setCompanyFooter(footer) {
    return Parse.Cloud.run('setCompanyFooter', { footer });
  },
  setCoachingLanguages(languages) {
    return Parse.Cloud.run('setCoachingLanguages', { languages });
  },
  async refreshAdminUsers() {
    const company = await query('Company')
      .equalTo('objectId', Parse.User.current().get('company').id)
      .first();
    const formattedCompany = CompanyService.formatCompany(company);
    store.commit('setAllAdminEmails', {
      [ADMIN_ROLES.COMPANY_ADMINS]: formattedCompany[ADMIN_ROLES.COMPANY_ADMINS] || [],
      [ADMIN_ROLES.UNIT_ADMINS]: formattedCompany[ADMIN_ROLES.UNIT_ADMINS] || [],
      [ADMIN_ROLES.COACHES]: formattedCompany[ADMIN_ROLES.COACHES] || [],
    });
  },
  findCompanyByEmail(email) {
    const domain = email.split('@')[1];
    return Parse.Cloud.run('findCompany', { domain });
  },
  async getCompanyData(email) {
    const user = await userService.getBasicUserInfo(email);
    const company = (user && user.company) || await this.findCompanyByEmail(email);
    return company;
  },
  async getCompanyLogo(email) {
    const company = await this.getCompanyData(email);
    return company && company.get('logo');
  }
};
