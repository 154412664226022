import googleAnalytics from '@kickbox/common-util/service/googleAnalytics';

export default {
  setUserData(userId, companyId, roles) {
    googleAnalytics.setUserProperties({
      roles: roles.join(),
      company: companyId || 'UNSET'
    });
    googleAnalytics.setUserId(userId);
  },

  // Custom events
  trackCustomEvent(name, parameters = {}) {
    googleAnalytics.sendEvent(name, parameters);
  }
};
