import companyStore from './company';

const state = {
  campaigns: []
};

const getters = {
  campaignById: (st) => (id) => st.campaigns.find((campaign) => campaign.id === id),
  campaigns: (st) => st.campaigns
};

const buildCampaignDTO = (campaign) => {
  const {
    id, cover, disabled, content, defaultLanguage,
    startDate, deadline, peopleSectionTitle, hideProjects
  } = campaign;
  const { company } = companyStore.state;

  const fallBackLang = Object.keys(content)[0];
  const translatedTitle = (content[company.language] && content[company.language].title)
    || (content[fallBackLang] && content[fallBackLang].title)
    || (content.EN && content.EN.title)
    || 'Unnamed campaign';

  return {
    id,
    title: translatedTitle,
    cover,
    disabled,
    content,
    defaultLanguage,
    startDate,
    deadline,
    peopleSectionTitle,
    people: !campaign.people ? [] : campaign.people.map((_) => ({
      id: _.id,
      name: _.name,
      photo: _.photo,
      username: _.username
    })),
    hideProjects
  };
};

const mutations = {
  setCampaigns(st, payload) {
    st.campaigns = payload.map((campaign) => buildCampaignDTO(campaign));
  },
  updateCampaign(st, payload) {
    const newCampaign = buildCampaignDTO(payload);

    if (st.campaigns.some((campaign) => campaign.id === payload.id)) {
      st.campaigns = st.campaigns.map((campaign) => (campaign.id === newCampaign.id
        ? Object.assign(campaign, newCampaign) : campaign));
    } else {
      st.campaigns = [...st.campaigns, newCampaign];
    }
  }
};

export default {
  state,
  getters,
  mutations
};
