import _getYoutubeId from 'get-youtube-id';
import { DEFAULT_PROJECT_VIDEO, S3_URL_PATTERN, TRIAL_VIDEO } from '../../constants/video';

function isTemporaryVideoForW3Compliant(url, origin) {
  return url.startsWith(`blob:${origin}/`);
}

function isTemporaryVideoForIE11(url) {
  return url.startsWith('blob:');
}

export default {
  methods: {
    isDefaultVideo(url) {
      return url.includes(DEFAULT_PROJECT_VIDEO);
    },
    isTrialVideo(url) {
      return url.includes(TRIAL_VIDEO);
    },
    isStoredVideo(url, storageKey) {
      const key = storageKey && storageKey.replace('videos', '');
      return url && url.includes(S3_URL_PATTERN) && url.includes(key);
    },
    isTemporaryVideo(url, origin) {
      if (url) {
        if (isTemporaryVideoForW3Compliant(url, origin)) {
          return true;
        }
        if (isTemporaryVideoForIE11(url)) {
          return true;
        }
      }
      return false;
    },
    getYoutubeId(url) {
      if (new RegExp('^http(s?)://').test(url)) {
        return _getYoutubeId(url, { fuzzy: false });
      }
      return null;
    },
    isYoutubeVideo(url) {
      return this.getYoutubeId(url) != null;
    },
    isValidMime(type) {
      return ['video/mp4', 'video/quicktime'].includes(type);
    }
  }
};
