import Parse from '@kickbox/common-util/src/parse';

export default {
  sendEmailToUser(emailProperties, userEmail, lang) {
    return Parse.Cloud.run('sendEmailCommunication', {
      ...emailProperties,
      emails: userEmail,
      language: lang
    });
  },
  sendPreviewEmail(emailProperties, userEmail) {
    return Parse.Cloud.run('sendEmail', {
      ...emailProperties,
      email: userEmail
    });
  }
};
