import Parse from '@kickbox/common-util/src/parse';

export default {
  getReactionBoard(projectId) {
    return Parse.Cloud.run('getReactionBoard', { projectId });
  },
  removeComment(projectId, commentId) {
    return Parse.Cloud.run('removeComment', { projectId, commentId });
  },
  async getProjectsReactionBoard(projects) {
    const projectsMap = new Map(projects.map((p) => [p.id, p]));
    const projectIds = [...projectsMap.keys()];
    const projectReactions = await Parse.Cloud.run('getProjectsReactionBoard', { projectIds });
    return projectReactions.map((r) => ({
      ...r,
      projectTitle: projectsMap.get(r.projectId).title,
      createdAt: projectsMap.get(r.projectId).createdAt
    }));
  }
};
