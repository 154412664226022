import * as Sentry from '@sentry/vue';

export default {
  setUserData(companyName, userId, roles) {
    Sentry.configureScope((scope) => {
      scope.setUser({ id: userId });
      scope.setExtra('roles', roles.join());
      scope.setExtra('company', companyName);
    });
  }
};
