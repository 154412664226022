import Vue from 'vue';
import Vuex from 'vuex';
import { SnackBarStore, LoaderStore, LayoutStore } from '@kickbox/common-admin';
import projects from './modules/projects';
import users from './modules/users';
import serviceProviders from './modules/serviceProviders';
import offers from './modules/offers';
import transactions from './modules/transactions';
import campaigns from './modules/campaigns';
import company from './modules/company';
import tags from './modules/tags';
import content from './modules/content';
import emailCoaching from './modules/emailCoaching';
import units from './modules/units';
import certificates from './modules/certificates';
import innoHub from './modules/innoHub';
import reactions from './modules/reactions';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    projects,
    users,
    transactions,
    serviceProviders,
    offers,
    company,
    campaigns,
    SnackBarStore,
    LoaderStore,
    LayoutStore,
    content,
    tags,
    emailCoaching,
    units,
    certificates,
    innoHub,
    reactions
  }
});
