const state = {
  offers: [],
  requests: [],
};

const getters = {
  offers: (st) => st.offers,
  requests: (st) => st.requests,
  offerById: (st) => (id) => st.offers.find((offer) => offer.id === id)
};

const mutations = {
  setOffers(st, payload) {
    st.offers = payload;
  },
  setRequestWithCount(st, payload) {
    st.requests = payload;
  },
  removeRequest(st, requestId) {
    st.requests = st.requests.filter((r) => r.id !== requestId);
  },
  updateOffer(st, payload) {
    st.offers = st.offers
      .map((offer) => (offer.id === payload.id ? Object.assign(offer, payload) : offer));
  },
};

export default {
  state,
  getters,
  mutations,
};
