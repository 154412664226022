const state = {
  certificates: [],
};

const getters = {
  certificates: (st) => st.certificates,
};

const mutations = {
  setCertificates(st, payload) {
    st.certificates = payload;
  }
};

export default {
  state,
  getters,
  mutations,
};
