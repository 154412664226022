export default {
  sendEvent(name, parameters = {}) {
    window.vm.$gtag.event(name, parameters);
  },

  setUserId(userId) {
    window.vm.$gtag.query('set', 'user_id', userId);
  },

  setUserProperties(properties) {
    window.vm.$gtag.query('set', 'user_properties', properties);
  },
};
