import Parse from '@kickbox/common-util/src/parse';
import { KICKBOX_ROLES } from '@kickbox/common-util';

export default {
  allSkills() {
    return new Parse.Query(Parse.Object.extend('Skills')).find()
      .then((skills) => {
        const skillArray = skills.map((skill) => [skill.get('name'), skill]);
        return new Map(skillArray);
      });
  },
  getSkills(obj) {
    const relation = obj.relation('skills');
    return relation.query().find();
  },
  addSkillOffer(name) {
    const Skill = Parse.Object.extend('Skills');
    return new Skill().save({
      name
    });
  },
  saveSkills(addSkillsArray, removeSkillsArray, obj) {
    if (addSkillsArray.length > 0 || removeSkillsArray.length > 0) {
      const relation = obj.relation('skills');
      let addSkillsPromise;
      let removeSkillsPromise;
      if (addSkillsArray.length > 0) {
        addSkillsPromise = this.getSkillsObjectArray(addSkillsArray)
          .then((addObject) => {
            relation.add(addObject);
          });
      }
      if (removeSkillsArray.length > 0) {
        removeSkillsPromise = this.getSkillsObjectArray(removeSkillsArray)
          .then((removeObject) => {
            relation.remove(removeObject);
          });
      }
      return Promise.all([addSkillsPromise, removeSkillsPromise])
        .then(() => obj.save());
    }
    return Promise.resolve();
  },
  addSkillsToDBIfDoesNotExists(skillsArray) {
    return this.allSkills()
      .then((allSkillMap) => skillsArray.filter((skill) => !allSkillMap.has(skill)))
      .then((saveSkills) => Promise.all(saveSkills.map((skill) => this.addSkillOffer(skill))));
  },
  getSkillsObjectArray(skillsArray) {
    return this.allSkills()
      .then((allSkillMap) => skillsArray.map((skill) => allSkillMap.get(skill)));
  },
  async getUsersSkills(user) {
    const skills = await user.get('skills').query().find();
    return skills.map((skill) => ({
      id: skill.id,
      name: skill.get('name')
    }));
  },
  getSkillsForExpert(users) {
    return Promise.all(users.map(async (user) => {
      const isExpert = user.role.includes(KICKBOX_ROLES.EXPERT);
      if (isExpert) {
        const skills = await this.getUsersSkills(user.parseObject);
        return {
          ...user,
          skills
        };
      }
      return user;
    }));
  },
};
