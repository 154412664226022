import { render, staticRenderFns } from "./EmailPreview.vue?vue&type=template&id=00bd2d4f&scoped=true&"
import script from "./EmailPreview.vue?vue&type=script&lang=js&"
export * from "./EmailPreview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00bd2d4f",
  null
  
)

export default component.exports