<template>
  <div
    v-if="url"
    class="mb-4"
  >
    <div
      v-if="isYoutubeVideo(url)"
      class="video-container"
    >
      <!--
      1015246848.0,
      4294967296.0,
        It restricts related videos to the same channel by setting rel=0
        https://developers.google.com/youtube/player_parameters#release_notes_08_23_2018
      -->
      <iframe
        v-if="isYoutubeAllowed"
        :src="`https://www.youtube-nocookie.com/embed/${getYoutubeId(url)}?rel=0&hl=${language}`"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="video-player"
      />
      <div
        v-else
        class="youtube-thumbnail-container"
      >
        <div>
          <a
            class="cky-banner-element"
            @click="openConsentPopup"
          >{{ acceptCookiesLabel }}
          </a>
        </div>
        <img
          :src="`https://img.youtube.com/vi/${getYoutubeId(url)}/maxresdefault.jpg`"
          class="youtube-thumbnail"
        >
      </div>
    </div>
    <div
      v-else
      class="video-container"
      data-recording-disable
    >
      <video
        ref="video"
        :src="url"
        controls
        class="video-player"
        controlsList="nodownload"
        @canplay="resume"
      >
        <slot name="videoFormatUnsupportedErrorMessage" />
      </video>
    </div>
  </div>
</template>

<script>
import video from '@kickbox/common-util/src/mixins/video';

export default {
  name: 'VideoPlayback',
  mixins: [video],
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentTime: -1,
    };
  },
  computed: {
    language() {
      return this.$i18n ? this.$i18n.locale : 'en';
    },
    acceptCookiesLabel() {
      return this.$t
        ? this.$t('video_playback.accept_cookies') : 'Please accept cookies to access this content';
    },
    isYoutubeAllowed() {
      const cookieValue = document.cookie
        .split('; ')
        .find((row) => row.startsWith('cookieyes-consent='))
        ?.split('=')[1];
      return cookieValue ? cookieValue.includes('advertisement:yes') : false;
    }
  },
  methods: {
    resume() {
      if (this.currentTime !== -1) {
        this.$refs.video.currentTime = this.currentTime;
        this.$refs.video.play();
        this.currentTime = -1;
      }
    },
    openConsentPopup() {
      window.revisitCkyConsent();
    }
  }
};
</script>

<style scoped lang="scss">
  video {
    max-height: 600px;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    .youtube-thumbnail-container {
      position: relative;
      a {
        color: #ffffff;
        text-decoration: none;
      }
      div {
        text-align: center;
        background-color: #000000cc;
        color: #ffffff;
        border-radius: 2px;
        cursor: pointer;
        position: absolute;
        top: 46%;
        padding: 10px 16px;
        z-index: 10;
        width: 100%;
      }
      .youtube-thumbnail {
        width: 100%;
      }
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(76, 72, 72, 0.7),rgba(76, 72, 72, 0.7));
      }
    }
  }

  .video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1%;
  }

  @media (max-width: 900px) {
    .youtube-thumbnail-container {
      a {
        font-size: 14px;
      }
    }
  }
</style>
