<template>
  <div
    :class="['cropper-container', isRound ? 'round' : 'rectangular']"
    :style="isRound ? '' : {paddingBottom: value ? 0 : `calc(100% / ${aspectRatio})`}"
  >
    <div
      v-if="value"
      :style="isRound ? '' : {paddingBottom: `${100 / aspectRatio}%`}"
    >
      <div
        :style="{ backgroundImage: `url(${croppedImage})` }"
        class="image"
        @click.self="editImage"
      >
        <img
          class="change-photo-icon"
          :src="require(isRound ? '../assets/images/upload-photo-icon-blue.svg' :
            '../assets/images/upload-photo-icon.svg')"
          @click="$refs.file.click()"
        >
      </div>
    </div>
    <div
      v-else
      @click="$refs.file.click()"
    >
      <div
        v-if="isRound"
        class="avatar"
      >
        <img
          :src="noAvatar"
          class="image"
        >
        <img
          src="../assets/images/upload-photo-icon-blue.svg"
          class="upload-photo-icon-round"
        >
      </div>
      <div
        v-else
        class="no-image flex-col flex-center justify-center"
      >
        <div
          v-ruid="'photo'"
          class="upload-photo-icon"
        />
        <div class="fw-14-600">
          {{ $t('common.placeholders.upload_photo') }}
        </div>
      </div>
    </div>
    <input
      id="fileInput"
      ref="file"
      type="file"
      accept="image/*"
      class="file-input"
      :disabled="disabled"
      @input="inputPhoto"
    >
    <div
      v-if="cropImageModal"
      class="cropper-modal"
    >
      <div class="cropper-modal-container">
        <h3>{{ $t('common.image_cropper.select_photo') }}</h3>
        <cropper
          v-if="value"
          ref="cropper"
          class="image-cropper"
          :stencil-component="isRound ? 'circle-stencil' : 'rectangle-stencil'"
          :stencil-props="{
            aspectRatio,
            movable: !disabled,
            scalable: !disabled,
          }"
          :src="value.startsWith('https') ? `${value}?t=${getRandomInt}` : value"
          :default-size="defaultSize"
          :default-position="defaultPosition"
          :restrictions="pixelsRestriction"
          :wheel-resize="!disabled"
        />
        <div class="flex-row form-buttons">
          <button
            v-ruid="'acceptButton'"
            class="btn lighter-blue-bg"
            :disabled="disabled"
            @click.prevent="cropPhoto"
          >
            {{ $t('common.image_cropper.select_photo_button') }}
          </button>
          <button
            class="btn cancel-btn"
            @click.prevent="cancelPhotoSelect"
          >
            {{ $t('common.buttons.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import { getRandomInt } from '@kickbox/common-util';
import noAvatar from '@/assets/img/no-avatar-icon.svg';

export default {
  components: {
    Cropper
  },
  props: {
    value: {
      type: String,
      default: null
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isRound: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cropImageModal: false,
      croppedImage: null,
      coordinates: null,
      oldValue: null,
      oldCoordinates: null,
      fileName: null,
      noAvatar
    };
  },
  computed: {
    getRandomInt() {
      return getRandomInt(100);
    },
  },
  watch: {
    value() {
      if (!this.croppedImage) {
        this.croppedImage = this.value;
      }
    }
  },
  mounted() {
    this.croppedImage = this.value;
  },
  methods: {
    defaultSize({ imageWidth, imageHeight }) {
      if (imageWidth / imageHeight < this.aspectRatio || imageWidth > imageHeight) {
        return {
          width: imageWidth,
        };
      }
      return {
        height: imageHeight
      };
    },
    defaultPosition({
      stencilWidth, stencilHeight, imageWidth, imageHeight
    }) {
      if (this.coordinates) {
        return this.coordinates;
      }
      return {
        left: imageWidth / 2 - stencilWidth / 2,
        top: imageHeight / 2 - stencilHeight / 2
      };
    },
    pixelsRestriction({ imageWidth, imageHeight }) {
      if (this.isRound) {
        const minWidthHeight = Math.min(imageWidth, imageHeight, 100);
        return {
          minWidth: minWidthHeight,
          minHeight: minWidthHeight
        };
      }
      const minWidth = Math.min(350, imageWidth, imageHeight * this.aspectRatio);
      const minHeight = Math.min(imageHeight, minWidth / this.aspectRatio);
      return {
        minWidth,
        minHeight
      };
    },
    inputPhoto() {
      if (this.$refs.file.files && this.$refs.file.files[0]) {
        const file = this.$refs.file.files[0];
        const reader = new FileReader();
        this.storeOriginalImage();
        this.coordinates = null;
        this.fileName = file.name;
        // set the file = null before changing the file so the cropper dont throws an error
        this.$emit('input', null);
        reader.onload = (e) => {
          this.$emit('input', e.target.result);
          this.$refs.file.value = null;
        };
        reader.readAsDataURL(file);
        this.cropImageModal = true;
      }
    },
    cropPhoto() {
      const { canvas, coordinates } = this.$refs.cropper.getResult();
      if (!canvas) return;
      this.cropImageModal = false;
      this.coordinates = coordinates;
      const resizeCanvas = this.resize_image(canvas);
      this.croppedImage = resizeCanvas.toDataURL('image/jpeg', 0.5);
      this.$emit('change', this.croppedImage, this.fileName);
    },
    resize_image(canvasImage, maxWidth = 651, maxHeight = 366) {
      // The max sizes are 2x what is currently used in the portal
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let ratio = 1;
      if (canvasImage.width > maxWidth) {
        ratio = maxWidth / canvasImage.width;
      } else if (canvasImage.height > maxHeight) {
        ratio = maxHeight / canvasImage.height;
      }

      canvas.width = canvasImage.width * ratio;
      canvas.height = canvasImage.height * ratio;
      ctx.drawImage(canvasImage, 0, 0,
        canvasImage.width, canvasImage.height,
        0, 0,
        canvas.width, canvas.height);
      return canvas;
    },
    cancelPhotoSelect() {
      this.cropImageModal = false;
      this.restoreOldImage();
    },
    editImage() {
      this.storeOriginalImage();
      this.cropImageModal = true;
    },
    storeOriginalImage() {
      this.oldValue = this.value;
      this.oldCoordinates = { ...this.coordinates };
    },
    restoreOldImage() {
      this.coordinates = { ...this.oldCoordinates };
      this.$emit('input', this.oldValue);
    }
  },
};
</script>

<style lang="scss" scoped>
$lighter-blue: #2b94fc;

.round {
  .image {
    width: 120px;
    height: 120px;
    background: center / cover no-repeat;
    position: relative;
    border-radius: 50%;
    cursor: pointer;

    .change-photo-icon {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

.rectangular {
  background-color: #f0f2f5;

  &.cropper-container {
  border-radius: 6px;
  }

  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: center center;
    background-size: cover;
    border-radius: 6px;
    cursor: pointer;

    .change-photo-icon {
      position: absolute;
      right: -4px;
      bottom: -4px;
    }
  }

  .no-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    color: #b6c2cd;
    text-transform: uppercase;
    cursor: pointer;
  }
}

.cropper-container {
  position: relative;

  .avatar {
    position: relative;
    cursor: pointer;

    .upload-photo-icon-round {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .file-input{
    display: none;
  }

  .cropper-modal{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: white;
    top: 0;
    left: 0;
    z-index: 3;
    padding-top: 76px;
    transition-duration: 0.5s;

    .cropper-modal-container {
      max-width: 90vw;
      width: 1000px;
      margin: 0 auto;

      h3 {
        text-align: center;
      }

      .image-cropper{
        max-height: calc(100vh - 300px);
      }

      .form-buttons {
        justify-content: center;

        .btn {
          padding: 0 50px;
        }
      }
    }
  }
}

::v-deep .vue-square-handler, ::v-deep .vue-simple-line {
  background-color: $lighter-blue;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media (min-width: 841px) {
  .btn {
    margin: 25px 8px;
  }
}

@media (max-width: 840px) {
  .image-cropper{
    max-height: calc(100vh - 350px);
  }

  .form-buttons {
    flex-direction: column;

    .btn {
      width: 100%;
      margin: 25px 0 0 0;
    }
  }
}
</style>
