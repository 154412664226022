import { BLUEBOX, GOLDBOX } from '@kickbox/common-util';
import { Parse, ContentService } from '@kickbox/common-admin';
import DEFAULT_CONTENT from '@kickbox/common-util/constants/default-content';
import store from '@/store';
import emailCoachingService from '@/services/emailCoachingService';
import emailCoachingLogService from '@/services/emailCoachingLogService';
import queueService from '@/services/queueService';
import phaseService from './phaseService';
import { projectUtils } from '../utils';
import commentService from '@/services/commentService';

export default {
  async changeProjectPhase(phaseName, project) {
    return Parse.Cloud.run('changeProjectPhaseByAdmin', {
      phaseName,
      projectId: project.id
    });
  },
  deleteProject(id) {
    // This is a soft delete
    store.commit('removeProject', { id });
    store.commit('removeRejectedProject', { id });
    this.emptyEmailCampaignQueue(id);
    return Parse.Cloud.run('disableProject', { projectId: id });
  },
  getAlumniContentTemplate(project, isAlumni) {
    const { language } = project.creator;
    const alumniContentKey = isAlumni
      ? DEFAULT_CONTENT.PAGES.PROJECT_ALUMNI_MESSAGE.key
      : DEFAULT_CONTENT.PAGES.PROJECT_BACK_FROM_ALUMNI_MESSAGE.key;
    return ContentService.getContent(
      alumniContentKey,
      store.getters.company.parseObject,
      language
    );
  },
  async manageAlumni(project, isAlumni) {
    const updateProperties = {
      ...project,
      isAlumni
    };
    if (isAlumni) {
      await this.emptyEmailCampaignQueue(project.id);
      updateProperties.emailCampaign = false;
    }
    store.commit('updateProject', updateProperties);
    const content = await this.getAlumniContentTemplate(project, isAlumni);
    return Parse.Cloud.run('manageProjectAlumni', {
      projectId: project.id,
      isAlumni,
      message: content.text
    });
  },
  async unsubscribeToEmailCampaign(id) {
    await this.emptyEmailCampaignQueue(id);
    await emailCoachingLogService.removeEmailCoachingLog(id);
    Parse.Cloud.run('unsubscribeToEmailCampaign', { projectId: id });
  },

  async emptyEmailCampaignQueue(projectId) {
    return queueService.deleteQueueBatch('args.projectId', projectId);
  },

  async subscribeToEmailCampaign(projectId, email, campaignInfo) {
    const startDate = new Date(campaignInfo.date);
    Parse.Cloud.run('setEmailCampaignNewProject', {
      projectId,
      startDate
    });

    const daysInMilliseconds = 86400000;
    const tags = 'email-coaching';

    const emailCoaching = await emailCoachingService.getEmailCoaching();
    emailCoaching.items.splice(0, campaignInfo.coachingNumber - 1);

    const deltaArray = emailCoaching.items.map((ec) => ec.delta);
    const deltaArrayFormatted = deltaArray.map((d, index, array) => Math.abs(d - array[0]));

    emailCoaching.items.forEach(({ key, name }, index) => {
      const processAfter = new Date(
        startDate.getTime() + (deltaArrayFormatted[index] * daysInMilliseconds)
      );
      const args = {
        unsubscribeLink: true,
        projectId,
        template: key,
        tags,
        email
      };
      queueService.addQueue('sendEmail', args, processAfter);
      emailCoachingLogService
        .addEmailCoachingLog(projectId, key, name, processAfter);
    });
  },

  async getAllCompanyProjects() {
    try {
      store.commit('setIsLoading', { projects: true });
      const projects = await this.getAdminProjects({ enabledProjects: true });
      // Commit projects and active times to store
      store.commit('setAllProjects', projects);
      store.commit('setActiveTimes', await phaseService.getActiveTimes());
      store.commit('setReactions', await commentService.getProjectsReactionBoard(projects) || []);
    } catch (error) {
      console.error('getAllCompanyProjects error', error);
    } finally {
      store.commit('setIsLoading', { projects: false });
    }
  },

  async fetchRejectedProjects() {
    store.commit('setIsLoading', { rejectedProjects: true });
    const allRejectedProjects = await this.getAdminProjects({ rejectedProjects: true });
    store.commit('setRejectedProjects', allRejectedProjects);
    store.commit('setIsLoading', { rejectedProjects: false });
  },

  async fetchProjectById(projectId) {
    store.commit('setIsLoading', { specificProject: true });
    const project = await Parse.Cloud.run('findDashboardProjectById', { projectId });
    store.commit('setIsLoading', { specificProject: false });
    return projectUtils.mapFormFormat(project);
  },

  async getSponsorsForProject(project, forceGetSponsors = false) {
    // The board and the manage project have different project properties.
    const phaseName = project.parseObject.get('phase')
      .get('name');
    if (phaseName === BLUEBOX.name || phaseName === GOLDBOX.name || forceGetSponsors) {
      return Parse.Cloud.run('getSponsorsForProject', { projectId: project.id })
        .catch((error) => {
          console.error('getSponsorsForProject', error);
        });
    }
    return [];
  },
  async addCreditToProject(bankAccountId, amount) {
    return Parse.Cloud.run('addCredit', {
      bankAccountId,
      amount
    });
  },
  async removeCreditFromProject(bankAccountId, amount) {
    return Parse.Cloud.run('removeCredit', {
      bankAccountId,
      amount
    });
  },
  updateProject(id, setProperties, unsetProperties) {
    return Parse.Cloud.run('updateProject', {
      id,
      setProperties,
      unsetProperties
    })
      .catch((error) => {
        console.error('updateProject error', error);
      });
  },
  async rejectProject(project, template) {
    await this.updateProject(project.id, { isRejected: true });
    store.commit('updateProject', {
      ...project,
      isRejected: true
    });
    this.fetchRejectedProjects();
    return this.sendEmailRejectedProject(project, template);
  },
  sendEmailApprovedProject(project, template) {
    this.sendEmailProject(project, true, template);
  },
  sendEmailRejectedProject(project, template) {
    this.sendEmailProject(project, false, template);
  },
  sendUnapprovedProjectEmail(project, template) {
    Parse.Cloud.run('projectUnapproved', {
      projectId: project.id,
      template
    });
  },
  sendEmailProject(project, approved, template) {
    Parse.Cloud.run('projectApprovedRejected', {
      projectId: project.id,
      projectApproved: approved,
      template
    });
  },
  async updateCrossCorporateProject(projectId, visibleCompanies, projectStore) {
    const saveCompanies = await Parse.Cloud.run('updateCrossCorporateProject', {
      projectId,
      visibleCompanies
    });
    store.commit('updateProject', {
      ...projectStore,
      crossCorporate: saveCompanies
    });
  },
  getUnit(unit) {
    return unit
      ? {
        id: unit.id,
        name: unit.get('name')
      }
      : null;
  },
  getNotes(project) {
    return project && project.get('notes') ? project.get('notes').map((_) => ({
      id: _.id,
      creator: _.get('user') ? _.get('user').get('name') : _.get('creator'),
      description: _.get('description'),
      isLearningNote: _.get('isLearningNote'),
      date: _.get('date'),
      createdAt: new Date(_.get('createdAt')),
      updatedAt: new Date(_.get('updatedAt')),
      parseObject: _
    })).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
  },
  async markProjectAsPending(project) {
    await this.updateProject(project.id, { isRejected: false });
    return Promise.all([this.getAllCompanyProjects(), this.fetchRejectedProjects()]);
  },
  async updateDashboardProjectInStore(projectId) {
    // TODO: we should not fetch again the project from the DB
    const project = await Parse.Cloud.run('findDashboardProjectById', { projectId });
    // workaround to get the sponsors, we should also remove this line after a code refactoring
    const sponsors = await this.getSponsorsForProject(project);
    const mappedProject = projectUtils.mapFormFormat({ ...project, sponsors });
    const projects = store.getters.dashboardProjects.map((p) => {
      if (p.id !== projectId) {
        return p;
      }
      return mappedProject;
    });
    store.commit('setDashboardProjects', projects);
  },
  /**
   * Fetch all projects from the database and maps them to the format needed for the
   * statistic dashboard.
   * Loads the projects afterwards in the dashboard store
   */
  async getDashboardProjects(includePhases = false) {
    store.commit('setIsLoading', { projects: true });
    const projects = await this.getAdminProjects({ includePhases });
    store.commit('setDashboardProjects', projects);
    store.commit('setIsLoading', { projects: false });
  },

  async getAdminProjects({
    includePhases = false,
    rejectedProjects = false,
    enabledProjects = false
  }) {
    const projects = await Parse.Cloud.run('getAdminProjects', {
      includePhases,
      rejectedProjects,
      enabledProjects
    });
    return projects
      .map((project) => projectUtils.mapFormFormat(project));
  },
  getTeamMembersForProject(projectId) {
    return Parse.Cloud.run('getTeamMembersForProject', { projectId });
  },
  async canIModifyProject(projectId) {
    return Parse.Cloud.run('canIModifyProject', { toBeModifiedProjectId: projectId });
  },
  async changeProjectOwner({ projectId, newOwnerId, userUnitId, userPhoneNumber }) {
    return Parse.Cloud.run('changeProjectOwner', {
      projectId,
      newOwnerId,
      userUnitId,
      userPhoneNumber
    });
  }
};
