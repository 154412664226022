import TRANSACTION_TYPE from '@kickbox/common-util/constants/transaction-type';
import { query } from '@kickbox/common-util/src/util/parse';
import Parse from '@kickbox/common-util/src/parse';
import moment from 'moment';
import store from '@/store';
import { campaignUtils } from '@/utils';

export default {
  async getTransactions() {
    store.commit('setIsLoading', { transactions: true });
    return Parse.Cloud.run('getTransactions')
      .then((transactions) => {
        store.commit('setTransactions', transactions);
        return transactions;
      })
      .catch((error) => {
        console.log('getTransactions error', error);
      })
      .finally(() => {
        store.commit('setIsLoading', { transactions: false });
      });
  },
  initializeCoinsTransaction() {
    store.commit('setCoinsTransactions', []);
    store.commit('setIsLoading', { coinsTransactions: true });
  },
  async getProjectCoinsHistory() {
    this.initializeCoinsTransaction();
    const transactions = await Parse.Cloud.run('getCoinTransactions', { type: 'coinsHistory' });
    return this.getCoinsTransactions(transactions);
  },
  async getCoinsConversion() {
    this.initializeCoinsTransaction();
    const transactions = await Parse.Cloud.run('getCoinTransactions', { type: TRANSACTION_TYPE.CONVERT_KICK_COINS });
    return this.getCoinsTransactions(transactions);
  },
  async getCoinsTransactions(transactions) {
    const bankAccounts = transactions.map((transaction) => transaction.get('sourceAccount') || transaction.get('targetAccount'));
    const projects = await query('Project')
      .include('parent')
      .include('belongsToUnit')
      .include('campaign')
      .containedIn('bankAccount', bankAccounts)
      .find();
    const projectMap = new Map(projects.map((project) => [
      project.get('bankAccount').id,
      {
        id: project.id,
        title: project.get('title'),
        creator: project.get('parent')?.get('name') || 'Unknown',
        unit: project.get('belongsToUnit') ? project.get('belongsToUnit').get('name') : 'no unit',
        campaign: campaignUtils.getCampaignTitle(project.get('campaign'), store.getters.company.language) || 'no campaign'
      }
    ]));
    const result = transactions.map((transaction) => {
      const project = projectMap.get(transaction.get('sourceAccount') || transaction.get('targetAccount'));
      const projectTitle = (project && project.title) || 'Unknown';
      const projectCreator = (project && project.creator) || 'Unknown';
      const projectUnit = (project && project.unit);
      const projectCampaign = (project && project.campaign);

      return {
        projectId: project && project.id,
        projectTitle,
        projectCreator,
        projectUnit,
        projectCampaign,
        transactionId: transaction.id,
        date: transaction.createdAt,
        amount: transaction.get('type') === TRANSACTION_TYPE.REMOVE_PROJECT_CREDIT ? -transaction.get('amount') : transaction.get('amount'),
        message: transaction.get('message')
      };
    });
    store.commit('setCoinsTransactions', result);
    store.commit('setIsLoading', { coinsTransactions: false });
    return result;
  },
  async handleCoinsConversion(projectId, transactionId) {
    return Parse.Cloud.run('handleAdminCoinsConversion', { projectId, transactionId });
  },
  /**
   * Get all transactions and store them the the vuex store.
   * The transactions are used the calculate the coins statistics
   */
  async getDashboardTransactions() {
    try {
      store.commit('setIsLoading', { transactions: true });
      const company = store.getters.company.parseObject;
      const transactions = await query('Transaction')
        .equalTo('company', company.id)
        .descending('createdAt')
        .limit(10000)
        .find();
      const projectMap = await this.getProjectBankAccountMap(transactions);

      // Map transactions to the correct format for the statistics and export
      const result = await Promise.all(transactions.map(async (transaction) => {
        // Get the project name and creator
        let project = null;
        if (transaction.get('type') === TRANSACTION_TYPE.ADD_PROJECT_CREDIT) {
          project = projectMap.get(transaction.get('targetAccount'));
        } else {
          project = projectMap.get(transaction.get('sourceAccount'));
        }
        let projectTitle = (project && project.title) || 'Unknown';
        const projectCreator = (project && project.creator) || 'Unknown';

        // Get the offer title when the transaction type is an offer request
        let offerTitle = null;
        if (transaction.get('type') === TRANSACTION_TYPE.REQUEST) {
          const request = await query('Request')
            .include('offer')
            .equalTo('objectId', transaction.get('request'))
            .first();
          offerTitle = request.get('offer') ? request.get('offer').get('title') : '';
          projectTitle = request.get('project') ? request.get('project').get('title') : '';
        }

        return {
          id: transaction.id,
          amount: transaction.get('amount'),
          type: transaction.get('type'),
          message: transaction.get('message'),
          createdAt: moment(transaction.createdAt),
          sourceAccount: transaction.get('sourceAccount'),
          projectTitle,
          projectCreator,
          offerTitle
        };
      }));
      store.commit('setDashboardTransactions', result);
    } catch (error) {
      console.log('getTransactions error', error);
    } finally {
      store.commit('setIsLoading', { transactions: false });
    }
  },
  /**
   * Creates a map with the id of the bank account as key and the project as value
   * @param {object[]} transactions
   */
  async getProjectBankAccountMap(transactions) {
    const bankAccountInstances = transactions
      .map((transaction) => {
        const bankAccountInstance = new Parse.Object('BankAccount');
        if (transaction.get('type') === TRANSACTION_TYPE.ADD_PROJECT_CREDIT) {
          bankAccountInstance.id = transaction.get('targetAccount');
        } else {
          bankAccountInstance.id = transaction.get('sourceAccount');
        }
        return bankAccountInstance;
      });

    const projects = await query('Project')
      .include('parent')
      .containedIn('bankAccount', bankAccountInstances)
      .limit(10000)
      .find();

    return new Map(projects.map((project) => [
      project.get('bankAccount').id,
      {
        title: project.get('title'),
        creator: project.get('parent').get('name')
      }
    ]));
  }
};
