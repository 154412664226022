const state = {
  transactions: [],
  coinsTransactions: [],
  dashboardTransactions: []
};

const getters = {
  transactions: (st) => st.transactions,
  coinsTransactions: (st) => st.coinsTransactions,
  dashboardTransactions: (st) => st.dashboardTransactions
};

const mutations = {
  setTransactions(st, payload) {
    st.transactions = payload;
  },
  setCoinsTransactions(st, payload) {
    st.coinsTransactions = payload;
  },
  setDashboardTransactions(st, payload) {
    st.dashboardTransactions = payload;
  },
  addTransaction(st, payload) {
    st.coinsTransactions.unshift(payload);
  },
};

export default {
  state,
  getters,
  mutations,
};
