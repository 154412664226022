import { instance } from '@kickbox/common-util/src/util/parse';

export default {
  createSponsor(projectId, name, unit, amount) {
    const project = instance('Project', { id: projectId });
    const sponsor = instance('Sponsor');
    return sponsor.save({
      name, unit, amount: Number(amount), project
    });
  },
  async deleteSponsor(sponsorId) {
    const sponsor = instance('Sponsor', { id: sponsorId });
    return sponsor.destroy();
  },
  async updateSponsor(sponsorId, name, unit, amount) {
    const sponsor = instance('Sponsor', { id: sponsorId });
    sponsor.set('name', name);
    sponsor.set('unit', unit);
    sponsor.set('amount', Number(amount));
    return sponsor.save();
  }
};
