import { Parse } from '@kickbox/common-admin';
import store from '@/store';

export default {
  async getRequests(company) {
    const userQuery = new Parse.Query(Parse.Object.extend('_User'))
      .equalTo('company', company);
    return new Parse.Query(Parse.Object.extend('Request'))
      .include('offer')
      .include('kickboxer')
      .matchesQuery('kickboxer', userQuery)
      .limit(10000)
      .find();
  },
  getOffers(company) {
    store.commit('setIsLoading', { offers: true });
    const query = new Parse.Query('Offer');
    const Company = Parse.Object.extend('Company');
    const companyObj = new Company();
    companyObj.id = company.id;
    query.equalTo('visibleCompanies', companyObj);
    query.notEqualTo('disabled', true);
    query.include('serviceProvider');
    query.include('visibleCompanies');
    query.descending('createdAt');
    query.limit(10000);
    return query.find()
      .then((offers) => {
        const mappedOffers = offers.map((offer) => ({
          id: offer.id,
          about: offer.get('about'),
          address: offer.get('address'),
          categories: offer.get('categories'),
          languages: offer.get('languages'),
          phases: offer.get('phases'),
          price: offer.get('price'),
          title: offer.get('title'),
          video: offer.get('video'),
          isPublished: offer.get('isPublished'),
          photo: offer.get('photo'),
          serviceProviderName: offer.get('serviceProvider') && offer.get('serviceProvider').get('name'),
          visibleCompanies: offer.get('visibleCompanies').map((visibleCompany) => visibleCompany.get('name')),
          parseObject: offer
        }));

        store.commit('setOffers', mappedOffers);
        return mappedOffers;
      })
      .finally(() => {
        store.commit('setIsLoading', { offers: false });
      });
  },

  async getRequestCount() {
    store.commit('setIsLoading', { offers: true });
    const [offers, requests] = await Promise.all([
      this.getOffers(store.getters.company), this.getRequests(store.getters.company.parseObject)
    ]);

    const requestCount = requests.reduce((acc, req) => {
      if (req.get('offer')) {
        const offerId = req.get('offer').id;
        if (!acc[offerId]) {
          acc[offerId] = 1;
        } else {
          acc[offerId] += 1;
        }
      }
      return acc;
    }, {});

    const offersWithCount = offers.map((acc) => {
      acc.numberOfRequests = requestCount[acc.id] || 0;
      acc.price = acc.price === 0 ? 'FREE' : acc.price.toString();
      return acc;
    });
    store.commit('setRequestWithCount', offersWithCount);

    store.commit('setIsLoading', { offers: false });

    return offersWithCount;
  },

  updateVisibleCompaniesOffer(parseObject, visibleCompanies, offerStore) {
    const Company = Parse.Object.extend('Company');
    const saveCompanies = visibleCompanies.map((company) => {
      const companyInstance = new Company();
      companyInstance.id = company.id;
      return companyInstance;
    });
    return parseObject.save({ visibleCompanies: saveCompanies })
      .then((result) => {
        const visibleCompaniesArray = result.get('visibleCompanies').map((company) => company.get('name'));
        store.commit('updateOffer', {
          ...offerStore,
          visibleCompanies: visibleCompaniesArray
        });
      });
  },

  /**
   * Deletes an offer
   *
   * @param {string} offerId The ID of the offer to delete.
   */
  async deleteOffer(offerId) {
    await Parse.Cloud.run('deleteOffer', { offerId });
  },

  getOfferOpenRequestsAmount(offerId) {
    return Parse.Cloud.run('getOfferOpenRequestsAmount', { offerId });
  },
  removeCompanyFromOffer(offerId) {
    return Parse.Cloud.run('removeCompanyFromOffer', { offerId });
  }
};
