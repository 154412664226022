import 'core-js/es';
import 'vuetify/dist/vuetify.min.css';
import { cloneDeep, debounce, difference, differenceBy, differenceWith, isEqual, shuffle, orderBy } from 'lodash';
import moment from 'moment';
import Sentry from '@kickbox/common-util/plugins/sentry';
import Vue from 'vue';
import Vuetify from 'vuetify';
import Croppa from 'vue-croppa';
import JsonExcel from 'vue-json-excel';
import VueGtag from 'vue-gtag';
import VueLodash from 'vue-lodash';
import VueSanitize from 'vue-sanitize';
import VueSync from 'vue-sync';
import VueHtml2Canvas from 'vue-html2canvas';

import { defaultSanitizeOptions } from '@kickbox/common-util';
import { Icons, AppButton } from '@kickbox/common-admin';
import VueRuid from '@kickbox/common-util/plugins/ruid';
import UserServiceAdmin from '@/services/userService';
import App from './App';
import router from './router';
import store from './store';
import '@mdi/font/css/materialdesignicons.css';

Sentry.init('admin');

// Configure Vue
Vue.config.productionTip = false;
Vue.component('DownloadExcel', JsonExcel);
Vue.component('AppButton', AppButton);
Vue.filter('formatDate', (date) => moment(date).format('MMM DD, YYYY'));
Vue.use(Croppa);
Vue.use(VueLodash, {
  name: '$lodash',
  lodash: {
    cloneDeep, debounce, difference, differenceBy, differenceWith, isEqual, shuffle, orderBy
  }
});
Vue.use(VueSanitize, defaultSanitizeOptions);
Vue.use(Vuetify);
Vue.use(VueRuid);
Vue.use(VueSync);
Vue.use(VueHtml2Canvas);

// Google Analytics initialization
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_BASE_ID_ADMIN,
    params: { send_page_view: false }
  }
}, router);

UserServiceAdmin.userIsLogged()
  .then((isLoggedIn) => {
    window.vm = new Vue({
      router,
      store,
      vuetify: new Vuetify({
        icons: {
          iconfont: 'mdi',
          values: Icons
        },
        theme: {
          themes: {
            light: {
              primary: '#2b94fc',
            },
          },
        },
      }),
      render: (h) => h(App)
    }).$mount('#app');
    if (isLoggedIn) {
      UserServiceAdmin.setAnalytics();
    }
  });
