const state = {
  reactions: [],
  commentsMap: []
};

const getters = {
  reactions: (st) => st.reactions,
  commentsMap: (st) => new Map(st.reactions.map((p) => [p.projectId, p.commentsAmount]))
};

const mutations = {
  setReactions(st, payload) {
    st.reactions = payload;
  },
};

export default {
  state,
  getters,
  mutations
};
