import {
  ALUMNI,
  PENDING,
  REDBOX_IDEA,
  REDBOX_FUNDING,
  BLUEBOX,
  GOLDBOX
} from '@kickbox/common-util';

/**
 * A map of project phases that contains their name, title and color.
 */
const phases = {
  [ALUMNI.name]: {
    name: ALUMNI.name,
    title: ALUMNI.title,
    color: '#8d98a5'
  },
  [PENDING.name]: {
    name: PENDING.name,
    title: PENDING.title,
    color: '#8d98a5'
  },
  [REDBOX_IDEA.name]: {
    name: REDBOX_IDEA.name,
    title: `${REDBOX_IDEA.title} ${REDBOX_IDEA.subTitle}`,
    color: '#f93b3b'
  },
  [REDBOX_FUNDING.name]: {
    name: REDBOX_FUNDING.name,
    title: `${REDBOX_FUNDING.title} ${REDBOX_FUNDING.subTitle}`,
    color: '#f93b3b'
  },
  [BLUEBOX.name]: {
    name: BLUEBOX.name,
    title: BLUEBOX.title,
    color: '#2b94fc'
  },
  [GOLDBOX.name]: {
    name: GOLDBOX.name,
    title: GOLDBOX.title,
    color: '#f5a623'
  },
};

/**
 * Returns the phase object that follows `phase`.
 *
 * @param {string|Object} phase The foregoing project phase (name or as object).
 *
 * @returns {Object}
 */
function nextPhase(phase) {
  const phaseName = typeof phase === 'object' ? phase.name : phase;

  const index = Object.keys(phases).indexOf(phaseName);
  if (index < 0) throw new Error(`Invalid phase name: ${phaseName}`);

  if (index + 1 === Object.keys(phases).length) return undefined;
  return Object.values(phases)[index + 1];
}

export default {
  phases,
  nextPhase
};
