import Vue from 'vue';
import Router from 'vue-router';
import FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import { TagList } from '@kickbox/common-admin';
import Parse from '@kickbox/common-util/src/parse';
import Layout from '@/components/Layout';
import { userService, offerService } from '@/services';
import { navigate } from '@/router/authGuard';
import store from '../store';
import { checkFeature, goToHome } from './authGuard';
import companyService from '@/services/companyService';

// Import routes
const ProjectsKanban = () => import(/* webpackChunkName: "ProjectsKanban" */ '@/components/projects/ProjectsKanban');
const DueProject = () => import(/* webpackChunkName: "DueProject" */ '@/components/projects/DueProjects');
const PendingProject = () => import(/* webpackChunkName: "PendingProject" */ '@/components/projects/PendingProjects');
const RejectedProjects = () => import(/* webpackChunkName: "RejectedProjects" */ '@/components/projects/RejectedProjects');
const ManageProject = () => import(/* webpackChunkName: "ManageProject" */ '@/components/projects/ManageProjects');
const ManageEditProject = () => import(/* webpackChunkName: "ManageEditProject" */ '@/components/projects/ManageEditProject');
const ManageProjectNotes = () => import(/* webpackChunkName: "ManageProjectNotes" */ '@/components/projects/ManageProjectNotes');
const ManageProjectComments = () => import(/* webpackChunkName: "ManageProjectNotes" */ '@/components/projects/ManageProjectComments');
const ManageUsers = () => import(/* webpackChunkName: "ManageUsers" */ '@/components/users/ManageUsers');
const ManageEditUser = () => import(/* webpackChunkName: "ManageEditUser" */ '@/components/users/ManageEditUser');
const CreateUsers = () => import(/* webpackChunkName: "CreateUsers" */ '@/components/users/CreateUsers');
const EmailCommunication = () => import(/* webpackChunkName: "EmailCommunication" */ '@/components/users/EmailCommunication');
const ManageCampaigns = () => import(/* webpackChunkName: "ManageCampaigns" */ '@/components/campaigns/ManageCampaigns');
const CampaignsEmailCommunication = () => import(/* webpackChunkName: "CampaignsEmailCommunication" */ '@/components/campaigns/CampaignsEmailCommunication');
const ManageEditCampaign = () => import(/* webpackChunkName: "ManageEditCampaign" */ '@/components/campaigns/ManageEditCampaign');
const CampaignSettings = () => import(/* webpackChunkName: "ManageEditCampaign" */ '@/components/campaigns/CampaignSettings');
const ServiceProvidersList = () => import(/* webpackChunkName: "ServiceProvidersList" */ '@/components/services/ServiceProvidersList');
const CoinsConversionList = () => import(/* webpackChunkName: "CoinsConversionList" */ '@/components/services/CoinsConversionList');
const CoinsAdditionDeductionList = () => import(/* webpackChunkName: "CoinsConversionList" */ '@/components/services/CoinsAdditionDeductionList');
const EditServiceProvider = () => import(/* webpackChunkName: "EditServiceProvider" */ '@/components/services/EditServiceProvider');
const Offers = () => import(/* webpackChunkName: "Offers" */ '@/components/services/Offers');
const ViewOffer = () => import(/* webpackChunkName: "ViewOffer" */ '@/components/services/ViewOffer');
const ManageTransactions = () => import(/* webpackChunkName: "ManageTransactions" */ '@/components/transactions/ManageTransactions');
const EmailCoachingContent = () => import(/* webpackChunkName: "EmailCoachingContent" */ '@/components/emailCoaching/Content');
const EmailCoachingSettings = () => import(/* webpackChunkName: "EmailCoachingSettings" */ '@/components/emailCoaching/Settings');
const Content = () => import(/* webpackChunkName: "Content" */ '@/components/settings/Content');
const Footer = () => import(/* webpackChunkName: "Footer" */ '@/components/settings/Footer');
const TermsConditions = () => import(/* webpackChunkName: "TermsConditions" */ '@/components/settings/TermsConditions');
const AdminUsers = () => import(/* webpackChunkName: "Admins" */ '@/components/settings/AdminUsers');
const CoachingLanguages = () => import(/* webpackChunkName: "CoachingLanguages" */ '@/components/settings/CoachingLanguages');
const OrderKickBoxes = () => import(/* webpackChunkName: "OrderKickBoxes" */ '@/components/order/OrderKickBoxes');
const InnovationShop = () => import(/* webpackChunkName: "InnovationShop" */ '@/components/innovationShop/Overview');
const InnovationShopDetailPage = () => import(/* webpackChunkName: "InnovationShopDetailPage" */ '@/components/innovationShop/DetailPage');
const PersonalDashboard = () => import(/* webpackChunkName: "PersonalDashboard" */ '@/components/statisticDashboard/PersonalDashboard');
const MainLogin = () => import(/* webpackChunkName: "Login" */ '@/components/login/MainLogin');
const Login = () => import(/* webpackChunkName: "Login" */ '@/components/login/Login');
const ResetPassword = () => import(/* webpackChunkName: "Login" */ '@/components/login/ResetPassword');
const LoginSso = () => import(/* webpackChunkName: "Login" */ '@/components/login/LoginSso');
const Certificates = () => import(/* webpackChunkName: "Certificates" */ '@/components/users/Certificates');
const Help = () => import(/* webpackChunkName: "Certificates" */ '@/components/Help');
const CoachingHub = () => import(/* webpackChunkName: "InnovationHub" */ '@/components/CoachingHub');
const OnboardingRoom = () => import(/* webpackChunkName: "OnboardingRoom" */ '@/components/OnboardingRoom');
const KnowledgeHub = () => import(/* webpackChunkName: "KnowledgeHub" */ '@/components/KnowledgeHub');
const Home = () => import(/* webpackChunkName: "Home" */ '@/components/home/Home');
const MigrationLogin = () => import(/* webpackChunkName: "Login" */ '@/components/migration/MigrationLogin');

Vue.use(Router);

async function loadOffers() {
  // Check if offer have been loaded yet
  if (!store.getters.offers.length) {
    await offerService.getOffers(store.getters.company);
  }
}

async function loginCheck(to, from, next) {
  const email = to.params.email || localStorage.getItem('loginEmail');
  if (email) {
    const company = await companyService.getCompanyData(email);
    const userName = to.params.userName || (await userService.getBasicUserInfo(email)).name;
    Object.assign(to.params, { email, company, userName });
    next();
  } else {
    next({ name: 'SignupLogin' });
  }
}

const router = new Router({
  routes: [
    {
      path: '/login',
      name: 'MainLogin',
      component: MainLogin,
      props: true,
      beforeEnter: async (to, from, next) => {
        // Check for sessionToken in query params (SSO)
        const { token } = to.query;
        if (token) {
          await userService.loginAdmin({ token });
        }
        return next();
      }
    },
    {
      path: '/login-sso/:email?',
      name: 'LoginSso',
      component: LoginSso,
      props: true,
      beforeEnter: (to, from, next) => loginCheck(to, from, next)
    },
    {
      path: '/login-password/:emailAddress',
      name: 'Login',
      component: Login,
      props: true,
    },
    {
      path: '/login/:emailProp',
      name: 'ResetPassword',
      component: ResetPassword,
      props: true
    },
    {
      path: '/login/migration/:email',
      name: 'MigrationLogin',
      component: MigrationLogin,
      props: true,
      beforeEnter: (to, from, next) => loginCheck(to, from, next)
    },
    {
      path: '/',
      alias: '/admin',
      name: 'AdminHome',
      component: Layout,
      children: [
        {
          path: '/home',
          name: 'Home',
          component: Home,
        },
        {
          path: '/dashboard',
          name: 'StatisticDashboard',
          component: PersonalDashboard,
        },
        {
          path: 'projects/board',
          name: 'AdminProjectsBoard',
          component: ProjectsKanban,
        },
        {
          path: 'projects/pending/:projectId?',
          name: 'AdminManagePendingProject',
          component: PendingProject,
          props: true,
        },
        {
          path: 'projects/due',
          name: 'AdminManageDueProject',
          component: DueProject,
        },
        {
          path: 'projects/rejected',
          name: 'AdminManageRejectedProject',
          component: RejectedProjects,
        },
        {
          path: 'projects/manage',
          name: 'AdminManageProject',
          component: ManageProject,
        },
        {
          path: 'projects/edit/:id/:alumni?',
          name: 'AdminManageProjectEdit',
          component: ManageEditProject,
          props: true,
        },
        {
          path: 'projects/manage/notes/:id',
          name: 'AdminManageProjectNotes',
          component: ManageProjectNotes,
          props: true,
        },
        {
          path: 'projects/manage/comments/:projectId',
          name: 'AdminManageProjectComments',
          component: ManageProjectComments,
          props: true,
        },
        {
          path: 'users/manage',
          name: 'AdminManageUsers',
          component: ManageUsers,
        },
        {
          path: 'users/manage/edit/:id',
          name: 'AdminManageEditUser',
          component: ManageEditUser,
          props: true,
        },
        {
          path: 'users/create',
          name: 'AdminCreateUsers',
          component: CreateUsers,
          beforeEnter: (to, from, next) => checkFeature(to, from, next, FEATURE_NAME.CREATE_USERS)
        },
        {
          path: 'users/email-communication',
          name: 'AdminEmailCommunication',
          component: EmailCommunication,
        },
        {
          path: 'users/certification',
          name: 'AdminUsersCertificates',
          component: Certificates,
        },
        {
          path: 'campaigns/manage',
          name: 'AdminManageCampaigns',
          component: ManageCampaigns,
          beforeEnter: (to, from, next) => checkFeature(to, from, next, FEATURE_NAME.CAMPAIGNS)
        },
        {
          path: 'campaigns/email',
          name: 'AdminCampaignEmailCommunication',
          component: CampaignsEmailCommunication,
          beforeEnter: (to, from, next) => checkFeature(to, from, next, FEATURE_NAME.CAMPAIGNS)
        },
        {
          path: 'campaigns/manage/create',
          name: 'AdminManageCreateCampaign',
          component: ManageEditCampaign,
          props: true,
          beforeEnter: (to, from, next) => checkFeature(to, from, next, FEATURE_NAME.CAMPAIGNS)
        },
        {
          path: 'campaigns/manage/edit/:id',
          name: 'AdminManageEditCampaign',
          component: ManageEditCampaign,
          props: true,
          beforeEnter: (to, from, next) => checkFeature(to, from, next, FEATURE_NAME.CAMPAIGNS)
        },
        {
          path: 'campaigns/settings',
          name: 'AdminCampaignSettings',
          component: CampaignSettings,
          props: true,
          beforeEnter: (to, from, next) => checkFeature(to, from, next, FEATURE_NAME.CAMPAIGNS)
        },
        {
          path: 'tags/list',
          name: 'TagList',
          component: TagList,
        },
        {
          path: 'services/service-providers',
          name: 'AdminManageServiceProviders',
          component: ServiceProvidersList,
        },
        {
          path: 'services/service-providers/edit-provider/:id',
          name: 'AdminManageEditServiceProviders',
          component: EditServiceProvider,
          props: true,
        },
        {
          path: 'services/offers',
          name: 'AdminManageOffers',
          component: Offers,
        },
        {
          path: 'services/offers/:id',
          name: 'AdminManageViewOffer',
          component: ViewOffer,
          props: true,
          beforeEnter: async (to, from, next) => {
            await loadOffers();
            next();
          }
        },
        {
          path: 'coins/conversion',
          name: 'CoinsConversion',
          component: CoinsConversionList,
        },
        {
          path: 'coins/addition-deduction',
          name: 'CoinsAdditionDeduction',
          component: CoinsAdditionDeductionList,
        },
        {
          path: 'transactions/manage',
          name: 'AdminManageTransactions',
          component: ManageTransactions,
        },
        {
          path: 'settings/content',
          name: 'Content',
          component: Content,
        },
        {
          path: 'email-coaching/content',
          name: 'EmailCoachingContent',
          component: EmailCoachingContent,
        },
        {
          path: 'email-coaching/settings',
          name: 'EmailCoachingSettings',
          component: EmailCoachingSettings,
        },
        {
          path: 'settings/footer',
          name: 'Footer',
          component: Footer,
        },
        {
          path: 'settings/admin-users',
          name: 'AdminUsers',
          component: AdminUsers,
        },
        {
          path: 'settings/terms-and-conditions',
          name: 'TermsConditions',
          component: TermsConditions,
        },
        {
          path: 'settings/coaching-languages',
          name: 'CoachingLanguages',
          component: CoachingLanguages,
        },
        {
          path: 'order/kickboxes',
          name: 'OrderKickBoxes',
          component: OrderKickBoxes,
        },
        {
          path: 'innovation-shop/:index?',
          name: 'InnovationShop',
          component: InnovationShop,
          props: true
        },
        {
          path: 'innovation-shop/service/:id',
          name: 'ServiceDetailPage',
          component: InnovationShopDetailPage,
          props: true,
        },
        {
          path: 'innovation-shop/best-practice/:id',
          name: 'BestPracticeDetailPage',
          component: InnovationShopDetailPage,
          props: true,
        },
        {
          path: 'help',
          name: 'Help',
          component: Help
        },
        {
          path: 'coachingHub',
          name: 'CoachingHub',
          component: CoachingHub,
          beforeEnter: (to, from, next) => checkFeature(to, from, next, FEATURE_NAME.COACHING_HUB),
          meta: {
            fillHeightContainer: true
          }
        },
        {
          path: 'onboarding',
          name: 'OnboardingRoom',
          component: OnboardingRoom,
          meta: {
            fillHeightContainer: true
          }
        },
        {
          path: 'knowledge-hub',
          name: 'KnowledgeHub',
          component: KnowledgeHub,
          meta: {
            fillHeightContainer: true
          }
        },
      ]
    }
  ],
});

router.beforeEach(async (to, from, next) => {
  const NOT_USER_ALLOWED = ['MainLogin', 'Login', 'LoginSso', 'ResetPassword', 'MigrationLogin'];
  const user = Parse.User.current();
  window.scrollTo({ top: 0 });
  // TODO: Find better way to handle routing
  if (user) {
    if (to.name === 'AdminHome') {
      goToHome(next, user);
    } else {
      navigate(to, from, next, user);
    }
  } else if (NOT_USER_ALLOWED.includes(to.name)) {
    next();
  } else {
    Parse.User.logOut();
    next('/login');
  }
});

export default router;
