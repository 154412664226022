import Parse from '@kickbox/common-util/src/parse';
import { ADMIN_ROLES } from '@kickbox/common-util/constants/admin-roles';
import store from '../store';

const COACH_ALLOWED = [
  'Login',
  'ResetPassword',
  'AdminHome',
  'AdminProjectsBoard',
  'AdminManagePendingProject',
  'AdminManageDueProject',
  'AdminManageRejectedProject',
  'AdminManageProject',
  'AdminManageProjectEdit',
  'AdminManageProjectNotes',
  'CoinsConversion',
  'CoinsAdditionDeduction',
  'AdminManageTransactions',
  'InnovationHub',
  'ServiceDetailPage',
  'BestPracticeDetailPage',
  'AdminUsersCertificates',
  'Help',
  'CoachingHub',
  'AdminManageProjectComments'
];

const UNIT_ADMIN_ALLOWED = [
  ...COACH_ALLOWED,
  'AdminManageUsers',
  'AdminManageEditUser',
  'InnovationHub',
  'ServiceDetailPage',
  'BestPracticeDetailPage',
  'AdminUsersCertificates'
];

export function hasAdminUserRole(user, adminUserRole) {
  const adminUsers = store.getters.company[adminUserRole] || [];
  return adminUsers.includes(user.get('email'));
}

export function goToHome(next, user) {
  if (hasAdminUserRole(user, ADMIN_ROLES.COMPANY_ADMINS)) {
    next('/Home');
  } else {
    next('/projects/board');
  }
}

export function checkFeature(to, from, next, necessaryFeature) {
  const user = Parse.User.current();
  if (necessaryFeature && !store.getters.company.features[necessaryFeature]) {
    goToHome(next, user);
  } else {
    next();
  }
}

export function navigate(to, from, next, user) {
  if (hasAdminUserRole(user, ADMIN_ROLES.COMPANY_ADMINS)
  || (hasAdminUserRole(user, ADMIN_ROLES.UNIT_ADMINS) && UNIT_ADMIN_ALLOWED.includes(to.name))
  || (hasAdminUserRole(user, ADMIN_ROLES.COACHES) && COACH_ALLOWED.includes(to.name))) {
    next();
  } else {
    goToHome(next, user);
  }
}
