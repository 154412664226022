import { Parse } from '@kickbox/common-admin';

export default {
  addEmailCoachingLog(projectId, templateId, templateName, scheduleDate) {
    const payload = {
      projectId,
      templateId,
      templateName,
      scheduleDate,
    };
    return Parse.Cloud.run('addEmailCoachingLog', payload);
  },
  removeEmailCoachingLog(projectId) {
    return Parse.Cloud.run('removeEmailCoachingLogs', { projectId });
  },
  getEmailCoachingLog(projectId) {
    return Parse.Cloud.run('fetchEmailCoachingLogs', { projectId });
  }
};
