import Parse from '@kickbox/common-util/src/parse';
import store from '@/store';
import translationService from './translationService';

export default {
  async fetchCampaignMembers(id) {
    store.commit('setIsLoading', { campaignMembers: true });
    const campaignMembers = await Parse.Cloud.run('fetchCampaignMembers', { id });
    store.commit('setIsLoading', { campaignMembers: false });
    return campaignMembers;
  },

  async getCampaigns(disabled = true) {
    store.commit('setIsLoading', { campaigns: true });
    const campaigns = await Parse.Cloud.run('fetchCampaigns', { includeOptions: { disabled } });
    store.commit('setCampaigns', campaigns);
    store.commit('setIsLoading', { campaigns: false });
    return campaigns;
  },

  async fetchCampaignById(id) {
    store.commit('setIsLoading', { campaigns: true });
    const campaign = await Parse.Cloud.run('fetchCampaignById', { id });
    store.commit('updateCampaign', campaign);
    store.commit('setIsLoading', { campaigns: false });
    return campaign;
  },

  async toggleCampaignStatus(campaign) {
    const newCampaign = campaign;
    newCampaign.disabled = !newCampaign.disabled;
    const savedCampaign = await this.saveCampaign(newCampaign);
    store.commit('updateCampaign', savedCampaign);
    return savedCampaign;
  },

  async saveCampaign(campaignObject) {
    const saveObj = campaignObject;
    saveObj.people = saveObj.people && Array.isArray(saveObj.people)
      ? saveObj.people.map((_) => _.id)
      : null;
    const campaign = await Parse.Cloud.run('saveCampaign', { payload: campaignObject });
    store.commit('updateCampaign', campaign);
    return campaign;
  },
  async getCampaignTranslations(companyLanguages) {
    const title = new Parse.Query('I18n')
      .equalTo('key', 'campaigns.campaign_about_title');
    const description = new Parse.Query('I18n')
      .equalTo('key', 'campaigns.campaign_about_description');
    // eslint-disable-next-line new-cap
    const translations = await new Parse.Query.or(title, description).find();
    const formattedTranslation = translations.map((t) => translationService
      .getAvailableTranslation(t, companyLanguages));
    return {
      title: formattedTranslation[1],
      description: formattedTranslation[0],
    };
  },
};
