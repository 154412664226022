import Parse from '@kickbox/common-util/src/parse';

export default {
  async getNotesFromProject(projectId) {
    return Parse.Cloud.run('getNotesFromProject', { projectId });
  },
  async addProjectNote(projectNotes, projectId) {
    return Parse.Cloud.run('createProjectNotes', { projectNotes, projectId });
  },
  async editProjectNotes(notesId, description, isLearningNote) {
    return Parse.Cloud.run('editProjectNotes', { notesId, description, isLearningNote });
  },
  async removeProjectNotes(notesId, projectId) {
    return Parse.Cloud.run('removeProjectNotes', { notesId, projectId });
  },
  sendCoachingMaterialEmail(projectId) {
    Parse.Cloud.run('sendCoachingMaterialEmail', { projectId });
  }
};
