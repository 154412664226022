import {
  REDBOX_IDEA, REDBOX_FUNDING, BLUEBOX, GOLDBOX, ALUMNI
} from '@kickbox/common-util';

const state = {
  projects: [],
  rejectedProjects: [],
  phases: [
    { text: `${REDBOX_IDEA.title} ${REDBOX_IDEA.subTitle}`, name: REDBOX_IDEA.name },
    { text: `${REDBOX_FUNDING.title} ${REDBOX_FUNDING.subTitle}`, name: REDBOX_FUNDING.name },
    { text: BLUEBOX.title, name: BLUEBOX.name },
    { text: GOLDBOX.title, name: GOLDBOX.name }
  ],
  activeTimes: {},
  projectsDrawer: null,
  dashboardProjects: [],
  projectCertificate: null
};

const getters = {
  projects: (st) => st.projects.filter((project) => !project.isPending),
  projectById: (st) => (id) => st.projects.find((project) => project.id === id),
  pendingProjects: (st) => st.projects.filter(
    (project) => project.isPending && !project.isRejected && !project.disabled
  ),
  dueProjects: (st) => st.projects
    .filter((project) => !project.isAlumni
      && project.phase.name.startsWith(REDBOX_IDEA.title.toLowerCase())
      && project.phase.endDate
      && Math.ceil((new Date(project.phase.endDate) - new Date()) / 1000 / 60 / 60 / 24) < 0)
    .map((project) => ({
      ...project,
      activeTime: st.activeTimes[project.id]
    })),

  rejectedProjects(st) {
    return st.rejectedProjects;
  },
  phases: (st) => st.phases,
  phaseByName: (st) => (phase) => st.phases.find((ph) => ph.value === phase),
  activeTimes: (st) => st.activeTimes,
  projectsWithActiveTime: (st) => st.projects
    .filter((project) => !project.isPending)
    .map((project) => ({
      ...project,
      activeTime: st.activeTimes[project.id]
    })),
  projectsDrawer: (st) => st.projectsDrawer,
  dashboardProjects: (st) => st.dashboardProjects,
  firstCreatedProject: (st) => st.dashboardProjects[0],
  projectCertificate: (st) => st.projectCertificate
};

const updateProject = (project, payload) => {
  if (project.id !== payload.id) {
    return project;
  }

  const newProject = Object.assign(project, payload);
  if (newProject.isAlumni) {
    newProject.phase.fullTitle = ALUMNI.title;
  } else {
    newProject.phase.fullTitle = newProject.phase.title;
  }
  return newProject;
};

const mutations = {
  setAllProjects(st, payload) {
    st.projects = payload;
  },
  setRejectedProjects(st, payload) {
    st.rejectedProjects = payload;
  },
  removeProject(st, project) {
    st.projects = st.projects.filter((prod) => prod.id !== project.id);
    st.dashboardProjects = st.dashboardProjects.filter((p) => p.id !== project.id);
  },
  removeRejectedProject(st, project) {
    st.rejectedProjects = st.rejectedProjects.filter((prod) => prod.id !== project.id);
  },
  updateRejectedProject(st, payload) {
    st.rejectedProjects = st.rejectedProjects.map((project) => updateProject(project, payload));
  },
  updateProject(st, payload) {
    st.projects = st.projects.map((project) => updateProject(project, payload));
  },
  setActiveTimes(st, payload) {
    st.activeTimes = payload;
  },
  addActiveTime(st, payload) {
    Object.assign(st.activeTimes, payload);
  },
  setDashboardProjects(st, payload) {
    st.dashboardProjects = payload;
  },
  updateDashboardProject(st, payload) {
    st.dashboardProjects = st.dashboardProjects.map((project) => (project.id === payload.id
      ? { ...project, ...payload } : project));
  },
  setProjectsDrawer(st, payload) {
    st.projectsDrawer = payload;
  },
  setProjectCertificate(st, payload) {
    st.projectCertificate = payload;
  }
};

export default {
  state,
  getters,
  mutations
};
