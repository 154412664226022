import moment from 'moment';

import emptyAvatar from '@/assets/img/no-avatar-icon.svg';
import { phaseUtils } from '@/utils';

/**
 * Maps a project from the store to the form format.
 *
 * @param {Object} project The project to map.
 *
 * @returns {Object}
 */
function mapFormFormat(project) {
  const startMonth = moment(project.startMonth, 'MMMM YYYY');
  const phaseStartDate = moment(project.phase.startDate).startOf('d');
  const phaseEndDate = moment(project.phase.endDate || undefined).endOf('d');

  return {
    ...project,
    description: project.description.startsWith('<p>') ? project.description : `<p>${project.description}</p>`,
    createdAt: moment(project.createdAt),
    updatedAt: moment(project.updatedAt || project.createdAt),

    // Current Phase
    phase: {
      ...project.phase,
      color: phaseUtils.phases[project.phase.name].color,
      startDate: phaseStartDate,
      endDate: phaseEndDate,
      duration: () => phaseEndDate.diff(phaseStartDate, 'd'),
      durationLeft: () => phaseEndDate.diff(moment().startOf('d'), 'd'),
      isDue: () => {
        // Never due if project is marked as alumni
        if (project.isAlumni) return false;

        // Due if the Requested Starting Month has elapsed
        if (project.phase.name === phaseUtils.phases.pending.name) {
          return moment().startOf('M').diff(startMonth, 'M') > 0;
        }

        // Due if remaining phase duration has run out
        if (project.phase.name.includes(phaseUtils.phases.redbox.name)) {
          return phaseEndDate.diff(moment().startOf('d'), 'd') <= 0;
        }

        // Bluebox and Goldbox projects are never due
        return false;
      }
    },

    // All Phases
    phases: project.phases.map((phase) => ({
      ...phase,
      createdAt: moment(phase.createdAt)
    })),

    // Requested Starting Month
    startMonth,

    // Email Campaign
    emailCampaign: project.emailCampaign
      ? moment(project.emailCampaign).format('YYYY-MM-DD')
      : null,

    // Notes
    notes: project.notes.map((n) => ({
      ...n,
      date: moment(n.date).format('h:mm A MMM DD, YYYY'),
    })).reverse(),

    // Creator
    creator: {
      ...project.creator,
      photo: project.creator.photo || emptyAvatar
    },

    // Coaches
    coaches: project.coaches.map((coach) => ({
      ...coach,
      photo: coach.photo || emptyAvatar
    }))
  };
}

export default {
  mapFormFormat
};
