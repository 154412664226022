const state = {
  company: null,
  companies: [],
  companiesMap: {},
  features: {},
  units: []
};

const getters = {
  units: (st) => st.units,
  company: (st) => st.company,
  companies: (st) => st.companies,
  companiesMap: (st) => st.companiesMap
};

const mutations = {
  setCompany(st, company) {
    const featuresMap = company.features.reduce((features, feature) => ({
      ...features,
      [feature]: true
    }), {});

    st.company = { ...company, features: featuresMap };
  },
  setAllAdminEmails(st, allAdminEmails) {
    st.company = { ...st.company, ...allAdminEmails };
  },
  setAdminUsers(st, { adminUsers, adminUserType }) {
    st.company = { ...st.company, [adminUserType]: adminUsers };
  },
  setUnits(st, units) {
    st.units = units.sort((a, b) => a.name.localeCompare(b.name));
  },
  setCompanies(st, companies) {
    const otherCompanies = companies.filter((company) => company.name !== st.company.name);
    st.companiesMap = new Map(otherCompanies.map((company) => [company.id, company]));
    st.companies = otherCompanies;
  },
  updateCompany(st, payload) {
    st.company = payload;
  }
};

export default {
  state,
  getters,
  mutations
};
