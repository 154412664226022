import { removeDuplicateOnArray } from '@kickbox/common-util';

const state = {
  defaultContent: [],
  customContent: []
};

const getters = {
  defaultContent: (st) => st.defaultContent,
  customContent: (st) => st.customContent
};

const mutations = {
  setDefaultContent(st, payload) {
    st.defaultContent = payload;
  },
  setCustomContent(st, payload) {
    st.customContent = payload;
  },
  updateCustomContent(st, payload) {
    st.customContent = st.customContent
      .map((dc) => (dc.name === payload.name && dc.language === payload.language
        ? Object.assign(dc, payload) : dc));
  },
  updateCustomContents(st, payload) {
    st.customContent = removeDuplicateOnArray(st.customContent.concat(payload));
  }
};

export default {
  state,
  getters,
  mutations
};
