const state = {
  emailCoaching: null,
};

const getters = {
  emailCoaching: (st) => st.emailCoaching,
};

const mutations = {
  setEmailCoaching(st, payload) {
    st.emailCoaching = payload;
  },
  updateEmailCoachingItem(st, payload) {
    st.emailCoaching.items = st.emailCoaching.items
      .map((ec) => (ec.key === payload.key ? Object.assign(ec, payload) : ec));
  },
  updateEmailCoachingItems(st, payload) {
    st.emailCoaching.items = payload;
  },
  removeEmailCoachingItem(st, payload) {
    st.emailCoaching.items = st.emailCoaching.items.filter((ec) => ec.key !== payload);
  }
};

export default {
  state,
  getters,
  mutations,
};
