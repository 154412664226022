<template>
  <div
    :class="{ large: !editMode }"
    class="mb-4"
  >
    <div
      v-if="showConversionMessage"
      class="conversion-message"
    >
      <img :src="conversionImage">
      <p>{{ conversionMessage }}</p>
    </div>
    <div
      v-else-if="showBrokenVideoMessage"
      class="conversion-message"
    >
      <img :src="brokenImage">
      <p>{{ brokenMessage }}</p>
    </div>
    <video-playback
      v-else-if="isLoaded && playbackUrl && !hideVideo"
      :url="playbackUrl"
    />
  </div>
</template>

<script>
import { getConvertedKey } from '@kickbox/common-util';
import { DEFAULT_PROJECT_VIDEO } from '@kickbox/common-util/constants/video';
import Parse from '@kickbox/common-util/src/parse';
import video from '@kickbox/common-util/src/mixins/video';
import conversionImage from '../assets/images/video-in-progress.svg';
import brokenImage from '../assets/images/video-convertion-failed.svg';
import VideoPlayback from './VideoPlayback';

export default {
  name: 'VideoDisplay',
  components: { VideoPlayback },
  mixins: [video],
  props: {
    url: {
      type: String,
      default: ''
    },
    editMode: {
      type: Boolean,
      default: false
    },
    storageKey: {
      type: String,
      default: ''
    },
    allowDefaultVideo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoaded: false,
      playbackUrl: '',
      conversionFailed: false,
      isConvertingVideo: false,
      conversionImage,
      brokenImage
    };
  },
  computed: {
    showConversionMessage() {
      return !this.isYoutubeVideo(this.url)
        && (this.isTemporaryVideo(this.url, window.location.origin)
          || (this.url && this.isConvertingVideo && !this.conversionFailed)
        );
    },
    hideVideo() {
      return !this.allowDefaultVideo && this.showDefaultVideo;
    },
    conversionMessage() {
      // Admin portal doesn't have i18n support
      return this.$t
        ? this.$t('common.video.conversion_message')
        : 'Your video is being converted for publication. This could take several hours. When it is ready, your video will be playable. Please reload the page before checking it.';
    },
    showBrokenVideoMessage() {
      return this.url && this.isConvertingVideo && this.conversionFailed && this.editMode;
    },
    brokenMessage() {
      // Admin portal doesn't have i18n support
      return this.$t
        ? this.$t('common.video.conversion_failure')
        : 'This  video can not be displayed. Please try uploading the video again.';
    },
    showDefaultVideo() {
      return this.allowDefaultVideo
        && (
          (this.url && this.isConvertingVideo && this.conversionFailed)
          || (!this.playbackUrl && !this.editMode)
          || (!this.playbackUrl && this.editMode)
          || this.isDefaultVideo(this.url)
        );
    },
    isConvertedUrl() {
      return this.playbackUrl && this.playbackUrl.includes(getConvertedKey(this.storageKey));
    }
  },
  watch: {
    url() {
      this.checkUrl();
    }
  },
  mounted() {
    this.checkUrl();
  },
  methods: {
    checkUrl() {
      if (!this.url) {
        this.playbackUrl = '';
        this.conversionFailed = false;
      } else {
        this.setVideoUrls();
      }
    },
    async setVideoUrls() {
      if (this.isYoutubeVideo(this.url)
        || this.isDefaultVideo(this.url)
        || this.isTrialVideo(this.url)
      ) {
        this.playbackUrl = this.url;
      } else if (this.isTemporaryVideo(this.url, window.location.origin)) {
        this.playbackUrl = '';
      } else {
        this.playbackUrl = await Parse.Cloud.run('getVideoPlaybackUrl', { key: this.storageKey });
        await this.checkVideoConversion();
        if (this.showDefaultVideo && !this.editMode) {
          this.playbackUrl = await Parse.Cloud.run('getFileUrl', { key: DEFAULT_PROJECT_VIDEO });
        } else if (this.editMode && !this.playbackUrl && this.url) {
          this.isConvertingVideo = true;
          this.conversionFailed = true;
        }
      }
      this.isLoaded = true;
    },
    async isVideoBeingConverted(key) {
      if (DEFAULT_PROJECT_VIDEO === key) {
        return false;
      }
      const { conversionInProgress, failure } = await Parse.Cloud.run('isVideoBeingConverted', { key });
      this.conversionFailed = failure;
      return conversionInProgress;
    },
    async checkVideoConversion() {
      this.isConvertingVideo = !!this.playbackUrl
        && (
          this.isTemporaryVideo(this.url, window.location.origin)
          || (
            !this.isYoutubeVideo(this.url)
            && !this.isConvertedUrl
            && this.isStoredVideo(this.url, this.storageKey)
            && await this.isVideoBeingConverted(this.storageKey)
          ));
    }
  }
};
</script>

<style lang="scss">
.conversion-message {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 129px;
  padding: 24px;
  background-color: #f9f9f9;
  position: relative;

  img {
    max-width: 100%;
    width: 70px;
    height: 80px;
    margin-right: 20px;
  }

  p {
    color: #676767;
    font-size: 11px;
  }
}

.large {
  .conversion-message {
    flex-direction: column;
    height: 270px;
    padding: 20px 20px;
    position: relative;

    img {
      margin: 20px auto 40px;
      width: 92px;
      height: 102px;
    }

    p {
      margin: 0 auto;
      text-align: center;
    }
  }
}
</style>
