import {
  Parse, CompanyService, UserService, RoleService, TagService, UnitService
} from '@kickbox/common-admin';
import { getUserLanguage, KICKBOX_ROLES } from '@kickbox/common-util';
import { ADMIN_ROLES } from '@kickbox/common-util/constants/admin-roles';
import { adminTypeToString } from '@kickbox/common-util/src/util/adminTypeToString';
import router from '@/router';
import store from '@/store';
import { hasAdminUserRole } from '@/router/authGuard';
import ProjectService from './projectService';
import CampaignService from './campaignService';
import AnalyticsService from './analyticsService';
import SentryService from './sentryService';
import { userService } from '@/services/index';
import projectService from '@/services/projectService';

export default {
  groupUsersByRoles() {
    return Parse.Cloud.run('groupUsersByRoles', {
      matchBy: 'name',
      matchWith: [
        KICKBOX_ROLES.KICKBOXER,
        KICKBOX_ROLES.EXPERT,
        KICKBOX_ROLES.VIEWER,
        KICKBOX_ROLES.SERVICE_PROVIDER
      ]
    });
  },

  async getUsersPerCompany() {
    store.commit('setIsLoading', { users: true });

    const usersGroupedByRoles = await this.groupUsersByRoles();

    await Promise.all(Object.keys(usersGroupedByRoles).map(async (roleName) => {
      usersGroupedByRoles[roleName] = await Promise
        .all(await this.resolveParseUsers(usersGroupedByRoles, roleName));
      return usersGroupedByRoles[roleName];
    }));

    // Remove users with kickbox or expert role from the viewer list
    usersGroupedByRoles[KICKBOX_ROLES.VIEWER] = window.vm.$lodash.differenceBy(
      window.vm.$lodash.differenceBy(usersGroupedByRoles[KICKBOX_ROLES.VIEWER],
        usersGroupedByRoles[KICKBOX_ROLES.KICKBOXER], 'email'),
      usersGroupedByRoles[KICKBOX_ROLES.EXPERT], 'email'
    );

    const allUsers = {};
    Object.entries(usersGroupedByRoles).forEach(([role, users]) => {
      users.reduce((accumulator, currentValue) => {
        function setRole(acc, user, roleName) {
          if (acc[user.id]) {
            acc[user.id].role.push(roleName);
          } else {
            user.role.push(roleName);
            acc[user.id] = user;
          }
          return acc;
        }
        // if the user has the Kickboxer role without an approval project,
        // set the role VIEWER instead
        if (role === KICKBOX_ROLES.KICKBOXER && !currentValue.parseObject.get('hasProject')) {
          const currentUser = accumulator[currentValue.id];
          const isExpert = currentUser && currentUser.role.includes(KICKBOX_ROLES.EXPERT);
          if (!isExpert) {
            return setRole(accumulator, currentValue, KICKBOX_ROLES.VIEWER);
          }
          return accumulator;
        }
        return setRole(accumulator, currentValue, role);
      }, allUsers);
    });
    const allUsersWithRole = Object.values(allUsers);
    store.commit('setUsers', allUsersWithRole);
    store.commit('setIsLoading', { users: false });
    store.commit('setUserSettings', Parse.User.current().get('settings'));
    return allUsersWithRole;
  },

  async resolveParseUsers(usersGroupedByRoles, roleName) {
    return usersGroupedByRoles[roleName].map(async (user) => ({
      id: user.id,
      name: user.get('name') || '',
      email: user.get('username') || '',
      language: getUserLanguage(user, store.getters.company.parseObject),
      role: [],
      selected: true,
      emailVerified: user.get('emailVerified') || '',
      emailVerifyToken: user.get('_email_verify_token') || '',
      unit: user.get('unit') ? {
        id: user.get('unit').id,
        name: user.get('unit').get('name')
      } : null,
      tagline: user.get('tagline') || '',
      about: user.get('about') || '',
      tags: TagService.buildTagDTO(user.get('tags')),
      contactInfo: user.get('contactInfo')
        ? user.get('contactInfo').filter((sp) => sp.type !== 'Email')
        : [],
      skills: [],
      createdAt: user.get('createdAt'),
      photo: user.get('photo'),
      loginMode: user.get('loginMode'),
      parseObject: user
    }));
  },

  async getServiceProviders() {
    store.commit('setIsLoading', { serviceProviders: true });
    const serviceProviders = await Parse.Cloud.run('getServiceProviders');
    store.commit('setServiceProviders', serviceProviders);
    store.commit('setIsLoading', { serviceProviders: false });
  },
  getUserRoles(user) {
    return [ADMIN_ROLES.UNIT_ADMINS, ADMIN_ROLES.COMPANY_ADMINS, ADMIN_ROLES.COACHES]
      .filter((value) => hasAdminUserRole(user, value))
      .map((value) => adminTypeToString(value, true));
  },
  setAnalytics() {
    const user = Parse.User.current();
    const { company } = window.vm.$store.getters;
    const roles = this.getUserRoles(user);
    AnalyticsService.setUserData(user.id, company.id, roles);
    SentryService.setUserData(company.name, user.id, roles);
  },
  async loginAdmin({ loginEmail, password, token }) {
    let user;
    try {
      if (loginEmail && password) {
        user = await Parse.User.logIn(loginEmail.toLowerCase(), password);
      } else {
        user = await Parse.User.become(token);
      }
      const email = user.get('username').toLowerCase();
      const company = !!user
        && user.get('company')
        && await CompanyService.getCompanyById(user.get('company').id);
      const isAdminUser = !!company && this.getAllAdminUsers(company).includes(email);
      if (!user || !isAdminUser) {
        store.dispatch('showSnackBar', {
          text: 'Incorrect email/password or does not have Admin privileges..'
        });
        return Parse.User.logOut();
      }
      localStorage.setItem('company', company.get('name'));
      await this.setAfterLogin(company);

      this.setAnalytics();

      if (hasAdminUserRole(user, ADMIN_ROLES.COMPANY_ADMINS)) {
        return router.push({ name: 'Home' });
      }
      return router.push({ name: 'AdminProjectsBoard' });
    } catch (error) {
      Parse.User.logOut();
      // https://docs.parseplatform.org/rest/guide/#error-codes
      if (error.code === 100) {
        store.dispatch('showSnackBar', { text: 'Server connection lost.' });
      } else if (error.code === 101) {
        store.dispatch('showSnackBar', {
          text: 'Incorrect email/password or does not have Admin privileges..'
        });
      } else if (error.code === 205) {
        store.dispatch('showSnackBar', { text: 'This email address is not verified' });
      } else {
        store.dispatch('showSnackBar', { text: `Unexpected error: ${error.code}` });
      }
      return error;
    }
  },
  getAllAdminUsers(company) {
    const companyAdmins = company.get(ADMIN_ROLES.COMPANY_ADMINS) || [];
    const unitAdmins = company.get(ADMIN_ROLES.UNIT_ADMINS) || [];
    const coaches = company.get(ADMIN_ROLES.COACHES) || {};
    return [
      ...companyAdmins,
      ...unitAdmins,
      ...coaches[ADMIN_ROLES.INTERNAL_COACHES] || [],
      ...coaches[ADMIN_ROLES.EXTERNAL_COACHES] || []
    ];
  },
  async userIsLogged() {
    const companyName = localStorage.getItem('company');
    try {
      const company = !!companyName && await CompanyService.getCompanyByName(companyName);
      const user = Parse.User.current();
      if (!company || !user) {
        throw new Error('Invalid user/company');
      }
      const adminEmail = user.get('email');
      const isAdminUser = this.getAllAdminUsers(company).includes(adminEmail);

      if (isAdminUser) {
        await this.setAfterLogin(company);
        return true;
      }
    } catch (error) {
      Parse.User.logOut();
    }
    localStorage.removeItem('company');
    return false;
  },
  async setAfterLogin(company) {
    store.commit('setCompany', CompanyService.formatCompany(company));

    const [crossCorporateCompanies, units, coaches] = await Promise.all([
      Parse.Cloud.run('listCompanies'),
      CompanyService.getUnitsByCompany(company),
      this.getAllAdminUsersByRole()
    ]);
    store.commit('setCompanies', crossCorporateCompanies);
    store.commit('setUnits', units);
    store.commit('setCoaches', coaches);
    ProjectService.getAllCompanyProjects();
    ProjectService.fetchRejectedProjects();
    CampaignService.getCampaigns();
    store.dispatch('getTags');
    store.dispatch('getGlobalTags');
  },
  getCurrentUserName() {
    return Parse.User.current().get('name');
  },
  getCurrentUserEmail() {
    return Parse.User.current().get('username');
  },
  resetPassword(email) {
    return Parse.User.requestPasswordReset(email);
  },
  getUser(email) {
    const query = new Parse.Query(Parse.Object.extend(Parse.User));
    const Company = Parse.Object.extend('Company');
    const companyInstance = new Company();
    companyInstance.id = store.getters.company.id;
    query.equalTo('company', companyInstance);
    query.equalTo('username', email);
    return query.first();
  },
  async getUsers(emailsArray) {
    return Parse.Cloud.run('getUsersByEmail', { emailsArray });
  },
  async getAdminUsersWithType(adminUserType) {
    const adminUserEmails = store.getters.company[adminUserType];
    if (adminUserEmails) {
      const adminUsers = await this.getUsers(adminUserEmails);
      return adminUsers.map((user) => ({
        id: user.id,
        name: user.get('name'),
        photo: user.get('photo'),
        username: user.get('username'),
        adminUserType
      }));
    }
    return [];
  },
  async getAllAdminUsersByRole() {
    const [companyAdmins, unitAdmins, coaches] = await Promise.all([
      this.getAdminUsersWithType(ADMIN_ROLES.COMPANY_ADMINS),
      this.getAdminUsersWithType(ADMIN_ROLES.UNIT_ADMINS),
      this.getAdminUsersWithType(ADMIN_ROLES.COACHES)
    ]);
    return [...companyAdmins, ...unitAdmins, ...coaches];
  },
  updateUser(entityProperties) {
    return Parse.Cloud.run('updateUser', entityProperties)
      .catch((error) => {
        console.log('cloudUpdateUser', error);
      });
  },
  deleteUser(userId) {
    return Parse.Cloud.run('deleteUser', { userId });
  },
  async createViewerUsers(emailsArray) {
    const user = await UserService.createUsersIfDoesNotExists(
      emailsArray, store.getters.company.id
    );
    await RoleService.addViewerRoleToUsers(emailsArray);
    return user;
  },
  async updateUserSettings(settings) {
    const entityProperties = {
      id: Parse.User.current().id,
      userProperties: {
        settings: {
          ...store.getters.userSettings,
          ...settings
        }
      }
    };

    await this.updateUser(entityProperties);

    // update user in localStorage so that the changes are still present after
    // a apge reload. When the user is not updated manualy in the localStorage
    // the changes are saved in database, but parse takes the old user from localStorage.
    const userStorage = JSON.parse(localStorage.getItem('Parse/kickbox/currentUser'));
    localStorage.setItem('Parse/kickbox/currentUser', JSON.stringify({
      ...userStorage,
      settings: entityProperties.userProperties.settings
    }));
    store.commit('setUserSettings', entityProperties.userProperties.settings);
  },
  async canUserRejectOrApproveProject(selectedCoaches, unit) {
    // we are mapping for 'email' or 'coach' becuase the coach-user object in
    // the board and in the list-view are different.
    const coaches = selectedCoaches.map((coach) => coach.email || coach.username);
    const currentUser = Parse.User.current();
    if (hasAdminUserRole(currentUser, ADMIN_ROLES.UNIT_ADMINS)) {
      const currentUnitAdmin = await UnitService.getAdminByUnit(unit);
      return !currentUnitAdmin.includes(currentUser.get('email'))
        && !coaches.includes(currentUser.get('email'));
    }
    if (hasAdminUserRole(currentUser, ADMIN_ROLES.COACHES)) {
      return !coaches.includes(currentUser.get('email'));
    }
    return false;
  },
  async canIModifyUser(userId) {
    return Parse.Cloud.run('canIModifyUser', { toBeModifiedUserId: userId });
  },
  getBasicUserInfo(email) {
    return Parse.Cloud.run('getBasicUserInfo', { email });
  },
  async getChangeOwnerUsers(ownerEmail) {
    const storeUsers = store.getters.users;
    let users = [];
    if (!storeUsers.length) {
      users = await userService.getUsersPerCompany();
    } else {
      users = storeUsers;
    }
    return users.filter((u) => u.email !== ownerEmail && u.emailVerified);
  },
  async updateChangeOwnerStore(project) {
    const { creator } = project;
    const { projectUnit } = project;
    const creatorProperties = {
      name: creator.name,
      email: creator.email
    };
    store.commit('updateProject', {
      creator: creatorProperties,
      projectUnit,
      id: project.id
    });
    const teamMembers = await projectService.getTeamMembersForProject(project.id) || [];
    return {
      creator: creatorProperties,
      projectUnit,
      teamMembers,
    };
  },
  async logout() {
    await Parse.User.logOut();
    localStorage.removeItem('company');
    // This a workaround, Logout and reload, you can only login after refreshing the page.
    window.location.href = '/';
  },
};
