/**
 * Generate the campaign title based on company language
 *
 * @param {Object} campaign The Campaign object
 * @param {String} companyLang Company language
 *
 * @returns {String}
 */
const getCampaignTitle = (campaign, companyLang) => {
  if (!campaign || !campaign.get('content')) {
    return null;
  }
  const campaignContent = campaign.get('content');
  const fallBackKey = Object.keys(campaignContent)[0];
  const campaignLang = campaign.get('defaultLanguage');

  return campaign && campaignContent
    ? ((campaignContent.hasOwnProperty(companyLang) && campaignContent[companyLang].title)
      || (campaignContent.hasOwnProperty(campaignLang) && campaignContent[campaignLang].title)
      || (campaignContent.hasOwnProperty(fallBackKey) && campaignContent[fallBackKey].title))
    : null;
};

export default {
  getCampaignTitle
};
