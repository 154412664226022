const state = {
  users: [],
  whiteListUsers: [],
  dashboardUsers: [],
  userSettings: null,
  allCoaches: []
};

const getters = {
  users: (st) => st.users,
  userById: (st) => (id) => st.users.find((user) => user.id === id),
  whiteListUsers: (st) => st.whiteListUsers,
  dashboardUsers: (st) => st.dashboardUsers,
  userSettings: (st) => st.userSettings,
  allCoaches: (st) => st.allCoaches
};

const mutations = {
  setCoaches(st, coaches) {
    st.allCoaches = coaches;
  },
  setWhitelistUser(st, payload) {
    st.whiteListUsers = payload;
  },
  deleteWhitelistUser(st, user) {
    const index = st.whiteListUsers.indexOf(user);
    st.whiteListUsers.splice(index, 1);
  },
  addWhitelistUser(st, user) {
    st.whiteListUsers.unshift(user);
  },
  setUsers(st, users) {
    st.users = users;
  },
  updateUser(st, payload) {
    st.users = st.users.map((user) => (user.id === payload.id
      ? Object.assign(user, payload)
      : user));
  },
  removeUser(st, payload) {
    st.users = st.users.filter((user) => user.id !== payload.id);
  },
  setDashboardUsers(st, payload) {
    st.dashboardUsers = payload;
  },
  setUserSettings(st, payload) {
    st.userSettings = payload;
  }
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
