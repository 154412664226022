import Parse from '@kickbox/common-util/src/parse';

export default {
  /**
   * Add a queue to Queue collection.
   * @param {string} jobType - Job type (Ex. 'sendEmail').
   * @param {object} args - Parameters specific to a kind of job.
   * @param {boolean} args.unsubscribeLink - Flag to show unsubscribe link in email.
   * @param {string} args.projectId - Target project Id.
   * @param {string} args.template - Template key.
   * @param {string} args.tags - Tags.
   * @param {string} args.email - Target email for 'sendEmail' jobs.
   * @param {number} processAfter - Time when job should be triggered. Difference in milliseconds.
   */
  async addQueue(jobType, args, processAfter) {
    const payload = {
      job: jobType,
      args,
      processAfter,
      attempts: 0
    };
    return Parse.Cloud.run('addQueue', { payload });
  },

  deleteQueueBatch(matchBy, matchWith) {
    return Parse.Cloud.run('deleteQueueBatch', { matchBy, matchWith });
  }
};
