import { Parse } from '@kickbox/common-admin';
import store from '@/store';

export default {
  async inviteServiceProvider(emails) {
    try {
      const promises = emails.map(async (email) => {
        const newServiceProvider = await Parse.Cloud.run('inviteServiceProvider', { email });
        store.commit('addServiceProvider', newServiceProvider);
      });
      await Promise.all(promises);
    } catch (error) {
      console.log('cloudInviteServiceProvider', error);
    }
  },

  async removeServiceProviderFromCompany(serviceProviderId) {
    return Parse.Cloud.run('removeServiceProviderFromCompany', { serviceProviderId })
      .then(() => store.commit('removeServiceProvider', serviceProviderId))
      .catch((error) => {
        console.log('cloudRemoveServiceProviderFromCompany', error);
      });
  }
};
