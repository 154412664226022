<template>
  <div>
    <app-layout
      :amount-due="dueProjects.length"
      :amount-pending="pendingProjects.length"
      :amount-rejected="rejectedProjects.length"
      :is-admin="true"
      :items="activeItems"
      :menu-names="menuNames"
      :portal-url="portalUrl"
    />
    <migration-dialog
      v-if="company && company.migrated && !allowedAccess"
      :value="company && company.migrated"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Layout } from '@kickbox/common-admin';
import * as FEATURE_NAME from '@kickbox/common-util/constants/feature-names';
import { ADMIN_ROLES } from '@kickbox/common-util/constants/admin-roles';
import Parse from '@kickbox/common-util/src/parse';
import UserService from '@/services/userService';
import OfferService from '@/services/offerService';
import CompanyService from '@/services/companyService';
import projectService from '@/services/projectService';
import transactionService from '@/services/transactionService';
import MigrationDialog from '@/components/migration/MigrationDialog';

export default {
  components: {
    appLayout: Layout,
    MigrationDialog
  },
  data() {
    return {
      menuNames: {
        AdminManageUsers: 'Manage Users',
        AdminEmailCommunication: 'Email Communication',
        AdminCreateUsers: 'Create Users',
        AdminManageEditUser: 'User Profile',
        AdminProjectsBoard: 'Projects Board',
        AdminManageDueProject: 'Due projects',
        AdminManagePendingProject: 'Pending projects',
        AdminManageRejectedProject: 'Rejected projects',
        AdminManageProject: 'Manage Projects',
        AdminManageProjectEdit: 'Edit Project',
        AdminManageProjectNotes: 'Project Notes',
        AdminManageServiceProviders: 'Service Providers',
        AdminManageEditServiceProviders: 'Edit Service Provider',
        AdminManageOffers: 'Offers',
        AdminManageViewOffer: 'View Offer',
        AdminManageTransactions: 'Service requests',
        AdminManageCampaigns: 'Manage Campaigns',
        AdminCampaignEmailCommunication: 'Email Communication',
        AdminCampaignSettings: 'Settings',
        AdminManageCreateCampaign: 'Create Campaign',
        AdminManageEditCampaign: 'Edit Campaign',
        CoinsConversion: 'Coins Conversion',
        CoinsAdditionDeduction: 'Addition / Deduction Coins',
        InnovationHub: 'Innovation Shop',
        EmailCoachingSettings: 'Email Coaching Settings',
        EmailCoachingContent: 'Email Coaching Content',
        Content: 'Content Settings',
        Footer: 'Footer Settings',
        TagList: 'Manage Tags',
        TermsConditions: 'Terms & Conditions',
        AdminUsers: 'Units and Admins',
        StatisticDashboard: 'Dashboard',
        AdminUsersCertificates: 'Certification',
        CoachingLanguages: 'Coaching Languages',
        Help: 'Help',
        CoachingHub: 'Coaching Hub',
        OnboardingRoom: 'Onboarding room',
        KnowledgeHub: ' Knowledge Hub',
        Home: 'Home'
      },
      portalUrl: process.env.VUE_APP_PORTAL_URL
    };
  },
  computed: {
    ...mapGetters([
      'pendingProjects',
      'company',
      'dueProjects',
      'rejectedProjects'
    ]),
    showOnboardingRoomLink() {
      return this.showIframeSection(this.company.onboardingRoomData);
    },
    showKnowledgeHubLink() {
      return this.showIframeSection(this.company.knowledgeHubData);
    },
    activeItems() {
      return [
        {
          action: '$houseIcon',
          menu: 'Home',
          to: { name: 'Home' },
        },
        {
          action: '$dashboard',
          menu: 'Dashboard',
          to: { name: 'StatisticDashboard' },
          disabledFor: [
            ADMIN_ROLES.UNIT_ADMINS,
            ADMIN_ROLES.COACHES
          ]
        },
        {
          action: '$project',
          menu: 'Projects',
          items: [
            {
              value: 'Board',
              to: { name: 'AdminProjectsBoard' }
            },
            {
              value: 'Manage',
              to: { name: 'AdminManageProject' },
              onClick() {
                projectService.getAllCompanyProjects();
              }
            },
            {
              value: 'Pending',
              to: { name: 'AdminManagePendingProject' },
              onClick() {
                projectService.getAllCompanyProjects();
              }
            },
            {
              value: 'Due',
              to: { name: 'AdminManageDueProject' },
              onClick() {
                projectService.getAllCompanyProjects();
              }
            },
            {
              value: 'Rejected',
              to: { name: 'AdminManageRejectedProject' },
              onClick() {
                projectService.fetchRejectedProjects();
              }
            }
          ]
        },
        {
          action: '$campaign',
          menu: 'Campaigns',
          items: [
            {
              value: 'Manage',
              to: { name: 'AdminManageCampaigns' }
            },
            {
              value: 'Email Communication',
              to: { name: 'AdminCampaignEmailCommunication' }
            },
            {
              value: 'Settings',
              to: { name: 'AdminCampaignSettings' }
            }
          ],
          featureRequired: FEATURE_NAME.CAMPAIGNS,
          disabledFor: [
            ADMIN_ROLES.UNIT_ADMINS,
            ADMIN_ROLES.COACHES
          ]
        },
        {
          action: '$service',
          menu: 'Services',
          items: [
            {
              value: 'Service Providers',
              to: { name: 'AdminManageServiceProviders' },
              onClick() {
                UserService.getServiceProviders();
              },
              disabledFor: [
                ADMIN_ROLES.UNIT_ADMINS,
                ADMIN_ROLES.COACHES
              ]
            },
            {
              value: 'Offers',
              to: { name: 'AdminManageOffers' },
              onClick() {
                OfferService.getRequestCount();
              },
              disabledFor: [
                ADMIN_ROLES.UNIT_ADMINS,
                ADMIN_ROLES.COACHES
              ]
            },
            {
              value: 'Service requests',
              to: { name: 'AdminManageTransactions' },
              onClick() {
                transactionService.getTransactions();
              }
            }
          ],
          featureRequired: FEATURE_NAME.SERVICES
        },
        {
          action: '$innovationShop',
          menu: 'Innovation Shop',
          to: { name: 'InnovationShop' }
        },
        ...(this.showKnowledgeHubLink ? [{
          action: '$knowledgeHubIcon',
          menu: 'Knowledge Hub',
          to: { name: 'KnowledgeHub' },
          disabledFor: [
            ADMIN_ROLES.UNIT_ADMINS,
            ADMIN_ROLES.COACHES
          ]
        }] : []),
        {
          action: '$coachingHub',
          menu: 'Coaching Hub',
          to: { name: 'CoachingHub' },
          tag: { text: 'Beta' },
          featureRequired: FEATURE_NAME.COACHING_HUB
        },
        ...(this.showOnboardingRoomLink ? [{
          action: '$onboardingRoom',
          menu: 'Onboarding Room',
          to: { name: 'OnboardingRoom' },
          disabledFor: [
            ADMIN_ROLES.UNIT_ADMINS,
            ADMIN_ROLES.COACHES
          ]
        }] : []),
        {
          action: '$coinsConvertion',
          menu: 'Coins',
          featureRequired: FEATURE_NAME.COINS,
          items: [
            {
              value: 'Conversion',
              to: { name: 'CoinsConversion' },
              featureRequired: FEATURE_NAME.COIN_CONVERSION,
              onClick() {
                transactionService.getCoinsConversion();
              }
            },
            {
              value: 'Addition / Deduction',
              to: { name: 'CoinsAdditionDeduction' },
              onClick() {
                transactionService.getProjectCoinsHistory();
              }
            }
          ]
        },
        {
          action: '$email',
          menu: 'Email Coaching',
          items: [
            {
              value: 'Settings',
              to: { name: 'EmailCoachingSettings' }
            },
            {
              value: 'Content',
              to: { name: 'EmailCoachingContent' }
            }
          ],
          disabledFor: [
            ADMIN_ROLES.UNIT_ADMINS,
            ADMIN_ROLES.COACHES
          ]
        },
        {
          action: '$people',
          menu: 'Users',
          items: [
            {
              value: 'Create',
              to: { name: 'AdminCreateUsers' },
              featureRequired: FEATURE_NAME.CREATE_USERS,
              disabledFor: [
                ADMIN_ROLES.UNIT_ADMINS
              ]
            },
            {
              value: 'Manage',
              to: { name: 'AdminManageUsers' },
              onClick() {
                UserService.getUsersPerCompany();
              }
            },
            {
              value: 'Email Communication',
              to: { name: 'AdminEmailCommunication' },
              onClick() {
                UserService.getUsersPerCompany();
              },
              disabledFor: [
                ADMIN_ROLES.UNIT_ADMINS
              ]
            },
            {
              value: 'Certification',
              to: { name: 'AdminUsersCertificates' }
            }
          ],
          disabledFor: [
            ADMIN_ROLES.COACHES
          ]
        },
        {
          action: '$settings',
          menu: 'Settings',
          items: [
            {
              value: 'Content',
              to: { name: 'Content' }
            },
            {
              value: 'Footer',
              to: { name: 'Footer' }
            },
            {
              value: 'Tags',
              to: { name: 'TagList' }
            },
            {
              value: 'Units & Admins',
              to: { name: 'AdminUsers' },
              onClick() {
                CompanyService.refreshAdminUsers();
              }
            },
            {
              value: 'Terms & Conditions',
              to: { name: 'TermsConditions' }
            },
            {
              value: 'Coaching Languages',
              to: { name: 'CoachingLanguages' }
            }
          ],
          disabledFor: [
            ADMIN_ROLES.UNIT_ADMINS,
            ADMIN_ROLES.COACHES
          ]
        }
      ];
    },
    allowedAccess() {
      const currentUser = Parse.User.current();
      return this.company && this.company.whiteEmailMigration === currentUser.get('email');
    }
  },
  methods: {
    showIframeSection(feature) {
      return !!(feature.enabled && feature.url);
    }
  }
};
</script>
