import Parse from '@kickbox/common-util/src/parse';
import { TagService } from '@kickbox/common-admin';

export default {
  ...TagService,
  getProjectTagCount() {
    return Parse.Cloud.run('getProjectTagCount');
  },
  getGlobalTags() {
    return Parse.Cloud.run('getTags', { global: true });
  }
};
