const state = {
  serviceProviders: [],
  providerContactInfo: []
};

function formatUserPropertiesValues(parseObject) {
  return {
    id: parseObject.id,
    name: parseObject.get('name'),
    email: parseObject.get('username'),
    address: parseObject.get('address'),
    tagline: parseObject.get('tagline'),
    about: parseObject.get('about'),
    companyVideo: parseObject.get('companyVideo'),
    contactInfo: parseObject.get('contactInfo')
  };
}

const getters = {
  serviceProviders: (st) => st.serviceProviders,
  providerContactInfo: (st) => st.providerContactInfo,
  serviceProviderById: (st) => (id) => st.serviceProviders.find(
    (provider) => provider.id === id
  )
};

const mutations = {
  setServiceProviders(st, payload) {
    st.serviceProviders = payload;
  },
  removeServiceProvider(st, serviceProviderId) {
    st.serviceProviders = st.serviceProviders.filter((sp) => sp.id !== serviceProviderId);
  },
  addServiceProvider(st, parseObject) {
    st.serviceProviders = [formatUserPropertiesValues(parseObject), ...st.serviceProviders];
  },
  updateServiceProvider(st, payload) {
    st.serviceProviders = st.serviceProviders
      .map((sp) => (sp.id === payload.id
        ? Object.assign(sp, formatUserPropertiesValues(payload))
        : sp));
  }
};

export default {
  state,
  getters,
  mutations
};
