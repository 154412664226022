import { TYPES } from '@kickbox/common-util/constants/inno-hub';

const state = {
  services: [],
  innoHubContent: []
};

const getters = {
  innoHubContent: (st) => st.innoHubContent,
  services: (st) => st.innoHubContent.filter((content) => content.type === TYPES.SERVICE),
  innoHubContentById: (st) => (id) => st.innoHubContent.find((content) => content.id === id),
};

const mutations = {
  setInnoHubContent(st, payload) {
    st.innoHubContent = payload;
  },
};

export default {
  state,
  getters,
  mutations
};
