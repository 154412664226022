import moment from 'moment';
import Parse from '@kickbox/common-util/src/parse';
import { REDBOX_IDEA } from '@kickbox/common-util';

const DATE_FORMAT = 'YYYY-MM-DD';

export default {
  // NOTE: Just reminding again here as very right place to do it
  // Phase needs to be refactored at DB level, proposed schema can be found
  // in jira confluence, and developers aware of the problem
  managePhaseDays(phaseId, days) {
    const Phase = Parse.Object.extend('Phase');
    const phaseInstance = new Phase();
    phaseInstance.id = phaseId;
    const newEndDate = moment(phaseInstance.get('endDate')).add(days, 'd').format(DATE_FORMAT);
    phaseInstance.set('endDate', newEndDate);
    phaseInstance.set('deadlineWarningSent', false);
    return phaseInstance.save();
  },
  getProjectPhase(parseProject) {
    const query = new Parse.Query(Parse.Object.extend('Phase'));
    query.equalTo('project', parseProject);
    query.descending('_created_at');
    return query.first();
  },
  getActiveTimes() {
    const query = new Parse.Query(Parse.Object.extend('Phase'));
    query.equalTo('subTitle', REDBOX_IDEA.subTitle);
    query.limit(10000);
    return query.find()
      .then((redBoxPhase) => redBoxPhase.reduce((objectPhase, currentPhase) => {
        const date = currentPhase.get('startDate');
        const activeTime = moment().startOf('day').diff(moment(date), 'days');
        const projectId = currentPhase.get('project').id;
        return Object.assign(objectPhase, {
          [projectId]: activeTime
        });
      }, {}));
  }
};

export {
  DATE_FORMAT
};
