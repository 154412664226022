<template>
  <div class="migration-container">
    <div
      class="flex flex-col align-center"
    >
      <img src="@/assets/img/migration.svg">
      <h3>{{ welcome }}</h3>
      <h3>{{ title }}</h3>
      <div class="description-container">
        <label>{{ description }}</label>
      </div>
    </div>
    <div class="form-element-wrap">
      <a
        class="btn"
        :href="`https://auth.rready.com/migration?email=${email}&action=${action}`"
        target="_blank"
        type="button"
        @click="clickedButton"
      >
        {{ button }}
      </a>
      <slot />
    </div>
  </div>
</template>

<script>
import analyticsService from '@/services/analyticsService';

export default {
  name: 'Migration',
  props: {
    email: {
      type: String,
      default: ''
    },
    newUser: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    welcome() {
      return this.$t ? this.$t('migration.welcome') : 'Big news!';
    },
    action() {
      return this.newUser ? 'register' : 'login';
    },
    title() {
      return this.$t ? this.$t('migration.title') : 'We’ve moved to our new platform!';
    },
    description() {
      return this.$t ? this.$t('migration.description') : 'Don\'t worry - your profile, ideas and comments have all been migrated to the new platform, so you can pick up where you left off! All you need to do is click the button below to login on the new platform. See you there!';
    },
    button() {
      return this.$t ? this.$t('migration.button.label') : 'Go to the new platform';
    },
  },
  methods: {
    clickedButton() {
      this.$emit('click');
      analyticsService.trackCustomEvent('migration-redirect-clicked');
    }
  }
};
</script>

<style scoped lang="scss">
.migration-container {
  width: 390px;
  div:first-child {
    padding: 10px 0;
  }
  img {
    margin-bottom: 20px;
  }
  h3 {
    text-align: center;
    max-width: 100%;
    margin: 2px 0;
    font-size: 30px;
    color: #4d4d4d;
    font-weight: 600;
    line-height: normal;
  }
  .description-container {
    margin: 20px 0 0;
    label {
      margin-bottom: 2rem;
      color: #9CA9B6;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      max-width: 100%;
      display: inline-block;
    }
  }
  .btn {
    display: block;
    padding: 0.9rem;
    font-weight: 600;
    font-size: 0.875rem;
    height: 48px;
    text-align: center;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #2b94fc;
    box-shadow: 0 8px 25px 0 rgba(43, 148, 252, 0.2);

    &:hover {
      background-color: #55a9fd;
    }
  }
  .flex {
    display: flex;
  }
  .flex-col {
    flex-direction: column;
  }
}
</style>
