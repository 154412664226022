import Parse from '@kickbox/common-util/src/parse';
import store from '@/store';
import queueService from './queueService';
import contentService from './contentService';

export default {
  async getEmailCoaching() {
    const emailCoachingDB = await this.getEmailCoachingFromDB();
    if (emailCoachingDB) {
      return {
        items: emailCoachingDB.get('items'),
        parseObject: emailCoachingDB,
        default: false,
      };
    }
    return {
      items: this.getDefaultEmailCoaching(),
      default: true,
    };
  },
  async getEmailCoachingItems() {
    return (await this.getEmailCoaching()).items;
  },
  getDefaultEmailCoaching() {
    return [
      { delta: 0, name: 'Email coaching "new project" #1', key: 'campainNewProject-1' },
      { delta: 7, name: 'Email coaching "new project" #2', key: 'campainNewProject-2' },
      { delta: 14, name: 'Email coaching "new project" #3', key: 'campainNewProject-3' },
      { delta: 21, name: 'Email coaching "new project" #4', key: 'campainNewProject-4' },
      { delta: 28, name: 'Email coaching "new project" #5', key: 'campainNewProject-5' },
      { delta: 35, name: 'Email coaching "new project" #6', key: 'campainNewProject-6' },
    ];
  },
  async removeEmailCoachingTemplate(templateKey) {
    await queueService.deleteQueueBatch('args.template', templateKey);
    await contentService.deleteContentBatch('name', templateKey);

    const emailCoachingObject = await this.getEmailCoachingFromDB();
    const emailCoachingItems = emailCoachingObject.get('items');
    const isFirstItem = emailCoachingItems[0].key === templateKey;
    const updatedItems = emailCoachingItems.filter((item) => item.key !== templateKey);
    if (isFirstItem) {
      updatedItems[0].delta = 0;
    }
    return emailCoachingObject.save({ items: updatedItems });
  },

  getEmailCoachingFromDB() {
    return Parse.Cloud.run('getEmailCoachings');
  },

  saveEmailCoaching(emailCoachingItems) {
    return Parse.Cloud.run('saveEmailCoaching', {
      items: emailCoachingItems
    });
  },

  updateEmailCoaching(emailCoachingObject, items) {
    return Parse.Cloud.run('updateEmailCoaching', {
      id: emailCoachingObject.id,
      items
    });
  },

  async addEmailCoachingTemplate(newTemplate, items) {
    let emailCoachingObject = await this.getEmailCoachingFromDB();
    if (!emailCoachingObject) {
      emailCoachingObject = await this.saveEmailCoaching(items);
      store.commit('setEmailCoaching', {
        default: false,
        items: emailCoachingObject.get('items'),
        parseObject: emailCoachingObject,
      });
    }
    const emailCoachingItems = emailCoachingObject.get('items');
    emailCoachingItems.push(newTemplate);
    return this.updateEmailCoaching(emailCoachingObject, emailCoachingItems);
  }
};
