const state = {
  adminUnits: [],
};

const getters = {
  adminUnits: (st) => st.adminUnits,
};

const mutations = {
  setAdminUnits(st, payload) {
    st.adminUnits = payload;
  }
};

export default {
  state,
  getters,
  mutations,
};
